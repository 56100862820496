
import { getExpedientes } from "@/services/expedientesclinicos/Expediente";
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Receta } from "@/interfaces/Receta";
import { Funcion } from "@/interfaces/Funcion";
import { Vitola } from "@/interfaces/Vitola";
import { Empleado } from "@/interfaces/Empleado";
import {
  getPeg,
  getMez,
  getEmp,
} from "@/services/expedientesclinicos/Empleado";
import { getLigas } from "@/services/expedientesclinicos/Liga";
import {
  createReceta,
  eliminateRecetas,
  createRecetaa,
  getOneReceta,
  deleteReceta,
  getReceta,
  updateReceta,
  getPrint,
} from "@/services/expedientesclinicos/Receta";
import { getVitolas } from "@/services/expedientesclinicos/Vitola";
import { getFuncions } from "@/services/expedientesclinicos/Funcion";
import { createMensaje } from "@/services/expedientesclinicos/ChatService";
import numeral from "numeral";
import moment from "moment";
// import Pusher from "pusher-js";

export default defineComponent({
  name: "recetas-form",
  components: {
    Navbar,
  },
  data() {
    return {
      medicamento: "",
      dosis: "",
      search: "",
      seguros2: [],
      seguros: [
        "NINGUNO",
        "ARS CMD",
        "MAPFRE SALUD ARS, S.A.",
        "FUTURO",
        "META SALUD",
        "YUNEN",
        "RESERVAS",
        "MONUMENTAL",
        "ASEMAP",
        "ARS APS S A",
        "SIMAG",
        "RENACER",
        "GRUPO MEDICO ASOCIADO",
        "PRIMERA ARS DE HUMANO",
        "UNIVERSAL",
        "HUMANO SEGUROS",
        "SENASA CONTRIBUTIVO",
        "SENASA SUBSIDIADO",
        "SEMMA",
        "IDOPPRIL",
        "ARS UASD",
        "OTRA",
      ],
      currentEdition: {} as any,
      vitolas: [] as Vitola[],
      empleados: [] as Empleado[],
      empleadosEmp: [] as Empleado[],
      empleadosPeg: [] as Empleado[],
      empleadosMez: [] as Empleado[],
      campoFocus: "fechaActual",
      mensageError: "Error",
      mensageExito: "Receta Registrada Exitosamente",
      mensageConfirm: "¿Está Seguro que desea Eliminar Este Receta?",
      encabezado: "",
      modoForm: "",
      funciones: [] as Funcion[],
      showDatosPadre: false,
      showDatosMadre: false,
      showDatosTutor: false,
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      receta: {} as Receta,
      oneReceta: {} as Receta,
      one: {},
      nextNo: Number,
      medicoSelected: [],
      clienteSelected: [],
      descripcionCliente: "",

      showInComentary: "",
      showModal: false,
      showModal7: false,
      showDelete: false,
      currentConsulta: "",
      estado: {} as object,
      printId: {} as any,
    };
  },

  async mounted() {
    if (this.$route.fullPath == "/recetas/new") {
      this.modoForm = "add";
      this.encabezado = "Nueva Receta";
    } else {
      this.modoForm = "show";
      this.encabezado = "Detalles de Receta";
    }

    if (this.modoForm == "add") {
      this.receta.no = 1;
      this.receta.consultas = [];
      this.fillFields();
      this.fixTimeAdd();
    } else {
      this.showDeleteMethod();
      if (typeof this.$route.params.id === "string") {
        await this.loadReceta(this.$route.params.id);
      }
      this.fixTime();
    }

    this.focus();
    this.loadExpedientes();
    // this.loadEmpleados();
    // this.loadLigas();
    // this.loadVitolas();
    // this.filterEmpleados();
  },

  methods: {
    toRxArray() {
      this.receta.rxs.push({ titu: this.medicamento });
    },

    newReceta() {
      this.$router.push(`/recetas/new`);
      this.showDelete = false;
      this.receta.rxs = [];
      this.modoForm = "add";
      this.encabezado = "Nueva Receta";
      document.getElementById("medicamento").focus();
    },
    async loadPrint() {
      try {
        const res = await getPrint();
        this.printId = res.data;
      } catch (error) {
        // console.error(error);
      }
    },

    async loadExpedientes() {
      this.toggleLoading();
      try {
        const res = await getExpedientes(this.$store.state.user.puedeVer);
        this.seguros = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    setSeguro(seguro: string, edad: number, cedula: string) {
      this.receta.seguro = seguro;
      this.receta.edad = edad;
      this.receta.cedula = cedula;
      this.showModalMethod7();
      this.search = "";
      document.getElementById("medicamento").focus();
    },

    buscar(term: string) {
      this.seguros2 = this.seguros;
      if (term !== "") {
        this.seguros2 = this.seguros.filter((seguro: any) => {
          return seguro.nombre
            .toLowerCase()
            .includes(this.search.toLowerCase());
        });
      }
      if (this.seguros2.length == 1) {
        if (confirm("¿" + this.seguros2[0].nombre + "?")) {
          this.setSeguro(
            this.seguros2[0].nombre,
            this.seguros2[0].edad,
            this.seguros2[0].cedula
          );
          // document.getElementById("oficio").focus();
        }
      }
    },

    showModalMethod7() {
      this.seguros2 = this.seguros;
      this.showModal7 = !this.showModal7;
      // document.getElementById("oficio").focus();
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    deleteService(it: any) {
      if (confirm("Está Seguro que desea Eliminar Esta Indicación?")) {
        this.receta.rxs.splice(it, 1);
      }
    },

    editService(it: number, medicamento: any) {
      this.medicamento = medicamento;
      this.receta.rxs.splice(it, 1);
    },

    newFormatDate(dateValue: Date) {
      // let out = moment(dateValue).add(0, "h");
      // return moment(out).format("DD/MM/YYYY");
      moment.locale("es");
      return moment(dateValue).calendar();
      // .startOf("hour")
      // .fromNow();
    },

    async addActivity() {
      if (this.medicamento !== "") {
        this.receta.rxs.push({
          titu: this.medicamento,
        });
        this.medicamento = "";
      }
      document.getElementById("medicamento").focus();
    },

    calcularEdad(date: any) {
      let years = 0;
      let edad = Math.floor(
        moment(new Date()).diff(moment(date, "YYYY-MM-DD"), "years", true)
      );
      if (edad > 120 || edad < 0) {
        years = 0;
      } else {
        years = edad;
      }
      this.receta.edad = years;
    },

    async filterEmpleados() {
      this.toggleLoading();
      try {
        const res = await getPeg();
        this.empleadosPeg = res.data;
      } catch (error) {
        // console.error(error);
      }

      try {
        const res = await getEmp();
        this.empleadosEmp = res.data;
      } catch (error) {
        // console.error(error);
      }

      try {
        const res = await getMez();
        this.empleadosMez = res.data;
      } catch (error) {
        // console.error(error);
      }

      this.toggleLoading();
    },

    async loadLigas() {
      // this.toggleLoading();
      try {
        const res = await getLigas();
        this.ligas = res.data;
      } catch (error) {
        // console.error(error);
      }
      // this.toggleLoading();
    },

    async loadVitolas() {
      // this.toggleLoading();
      try {
        const res = await getVitolas();
        this.vitolas = res.data;
      } catch (error) {
        // console.error(error);
      }
      // this.toggleLoading();
    },

    // async loadEmpleados() {
    //   this.toggleLoading();
    //   try {
    //     const res = await getEmpleados();
    //     this.empleados = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   this.toggleLoading();
    // },

    fixTimeAdd() {
      this.receta.fechaActual = this.formatDateToFixAdd(
        this.receta.fechaActual,
        true
      );
    },

    fixTime() {
      this.receta.fecha = this.formatDateToFix(this.receta.fecha, true);
      this.receta.fechaActual = this.formatDateToFix(
        this.receta.fechaActual,
        true
      );
    },

    formatDateToFixAdd(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "h");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DD");
      }
    },

    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(4, "h");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DD");
      }
    },

    async loadReceta(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getReceta(id);
        this.receta = data;
        // this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },

    async handleUpdate() {
      this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.receta.userMod = this.$store.state.user.usuario;
          await updateReceta(this.$route.params.id, this.receta);
          this.addMessage();
          this.$router.push("/recetas");
        }
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
      // this.toggleAlert();
    },

    async handleDelete() {
      if (confirm(this.mensageConfirm)) {
        try {
          if (typeof this.$route.params.id === "string") {
            await deleteReceta(this.$route.params.id);
            this.addMessage();
            this.$router.push("/recetas");
          }
        } catch (error) {
          //console.error(error);
        }
      }
    },

    showDeleteMethod() {
      if (this.$store.state.user.type == "Power User") {
        this.showDelete = true;
      }
    },

    toggleShowDatosPadre() {
      this.showDatosPadre = !this.showDatosPadre;
    },
    toggleShowDatosMadre() {
      this.showDatosMadre = !this.showDatosMadre;
    },
    toggleShowDatosTutor() {
      this.showDatosTutor = !this.showDatosTutor;
    },

    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    isError(message: string) {
      if (message == this.mensageExito) {
        return "success";
      } else {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcReceta() {
      (this.receta.retension = this.receta.bruto * 0.1),
        (this.receta.neto = this.receta.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fillFields() {
      this.receta.fechaActual = new Date();
      this.receta.fecha = new Date();
      this.receta.rxs = [];
    },

    async loadOneReceta() {
      try {
        const res = await getOneReceta();
        this.oneReceta = res.data;
        this.one = this.oneReceta[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        if (this.nextNo == null) {
          this.nextNo = 0;
        }
        this.receta.no = this.nextNo;
        this.receta.principal = this.nextNo;
        this.receta.principal = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveRecetaa() {
      await this.loadOneReceta();
      try {
        const res = await createRecetaa(this.servicio);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveReceta() {
      this.toRxArray();
      this.toggleLoading();
      try {
        try {
          const res = await getOneReceta();
          this.oneReceta = res.data;
          this.one = this.oneReceta[0];
          this.nextNo = this.one.no + 1;
          this.receta.no = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.receta.userReg = this.$store.state.user.usuario;
        const res = await createReceta(this.receta).then(
          (res) => {
            this.error = this.respuesta = res.data.title;
            // this.$router.push("/");
            this.res = res;
            this.respuesta = res.data;
            this.addMessage();
          },
          (err) => {
            // console.log(err.response);
            this.error = err.response.data.error;
          }
        );
        // this.$router.push("/recetas/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      if (this.error !== this.mensageError) {
        await this.cleanFields();
      }
      await this.fillFields();
      document.getElementById(this.campoFocus).focus();
      this.toggleAlert();
      // Impresion Directa
      await this.loadPrint();
      this.$router.push(`/recetas2/${this.printId._id}`);
    },

    async deleteAllRecetas() {
      try {
        const res = await eliminateRecetas(this.receta);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    cleanFields() {
      this.receta.nombre = "";
      this.receta.referidoPor = "";
      this.receta.fechaActual = "";
      this.receta.fechaNacimiento = "";
      this.receta.edad = "";
      this.receta.sexo = "";
      this.receta.cedula = "";
      this.receta.telefono = "";
      this.receta.direccion = "";
      this.receta.seguro = "";
      this.receta.oficio = "";
      this.receta.deporte = "";
      this.receta.ladoDominante = "";
      this.receta.app = "";
      this.receta.habitosToxicos = "";
      this.receta.antQuirurgicos = "";
      this.receta.alergias = "";
      this.receta.mecanismoLesion = "";
      this.receta.tiempoEvolucion = "";
      this.receta.elevacionAnteriorActiva = "";
      this.receta.elevacionAnteriosPasiva = "";
      this.receta.rotacionInterna = "";
      this.receta.rotacionExterna = "";
      this.receta.abd = "";
      this.receta.jobe = "";
      this.receta.speed = "";
      this.receta.obrien = "";
      this.receta.naponeon = "";
      this.receta.acromioClavicular = "";
      this.receta.inestabilidadAnterior = "";
      this.receta.inestabilidadPosterior = "";
      this.receta.cajonAnterior = "";
      this.receta.cajonPosterior = "";
      this.receta.lachman = "";
      this.receta.appley = "";
      this.receta.bostezoLateral = "";
      this.receta.bostezoMedial = "";
      this.receta.otraLesion = "";
      this.receta.estudiosSolicitados = "";
      this.receta.diagnosticoPresuntivo = "";
      this.receta.diagnosticoImagen = "";
      this.receta.medicamentos = "";
      this.receta.terapiaFisica = "";
      this.receta.cirugia = "";
      this.receta.consultas = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById(this.campoFocus).focus();
    },
    focus2() {
      document.getElementById(this.campoFocus2).focus();
    },
  },
});
