
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Area } from "@/interfaces/Area";
import { getAreas } from "@/services/expedientesclinicos/AreaService";
import { deleteArea, getArea, updateArea } from "@/services/expedientesclinicos/AreaService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "area-list",
  components: {
    Navbar,
  },
  data() {
    return {
      areas: [] as Area[],
      showAlert: false,
      cargando: false,
      clienteSelected: [],
      medicoSelected: [],
      area: {} as Area,
      estado: {} as object,
    };
  },
  methods: {
    async loadAreas() {
      try {
        const res = await getAreas();
        this.areas = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    async facturar() {
      this.area.cantPrefactura = 0;
      this.area.cantFactura = 1;
      this.area.credito = 0;
      this.area.debito = 0;
      this.area.subTipo = "Factura";
      this.area.factura = this.area.prefactura;
      this.area.prefactura = 0;
      await this.selectCliente(this.area.descCliente);
      this.area.literal = this.clienteSelected[0].tipoComprobante.substring(
        0,
        3
      );
      await this.selectMedico(this.area.medico);
      if (this.area.literal == "B15") {
        this.area.ncf = this.medicoSelected[0].compGuberAsig;
        this.area.vencimiento = this.medicoSelected[0].fechGuberAsig;
        this.area.fechaNCF = new Date();
      } else {
        this.area.ncf = this.medicoSelected[0].compNoGuberAsig;
        this.area.vencimiento = this.medicoSelected[0].fechNoGuberAsig;
        this.area.fechaNCF = new Date();
      }
      this.handleUpdate();
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },

    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fixTime() {
      this.area.fechaProceso = this.formatDate(this.area.fechaProceso);
      this.area.fechaEfectivo = this.formatDate(this.area.fechaEfectivo);
      this.area.fechaVence = this.formatDate(this.area.fechaVence);
    },

    async loadArea(id: string) {
      this.toggleLoading();
      this.loadAreas();
      try {
        const { data } = await getArea(id);
        this.area = data;
        this.estado = this.area.estado;
        this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },
    
    async handleUpdate() {
      this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.area.userMod = this.$store.state.user.usuario;
          await updateArea(this.$route.params.id, this.area);
          if (this.area.literal == "B15") {
            this.medicoSelected[0].compGuberAsig += 1;
          } else {
            this.medicoSelected[0].compNoGuberAsig += 1;
          }
          this.$router.push("/areas");
        }
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
      this.toggleAlert();
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteArea(this.$route.params.id);
          this.$router.push("/areas");
        }
      } catch (error) {
        //console.error(error);
      }
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadArea(this.$route.params.id);
    }
  },
});
