
import { getExpedientes } from "@/services/expedientesclinicos/Expediente";
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Cita } from "@/interfaces/Cita";
import { Funcion } from "@/interfaces/Funcion";
import { Vitola } from "@/interfaces/Vitola";
import { Empleado } from "@/interfaces/Empleado";
import {
  getPeg,
  getMez,
  getEmp,
} from "@/services/expedientesclinicos/Empleado";
import { getLigas } from "@/services/expedientesclinicos/Liga";
import {
  createCita,
  eliminateCitas,
  createCitaa,
  getOneCita,
  deleteCita,
  getCita,
  updateCita,
} from "@/services/expedientesclinicos/Cita";
import { getVitolas } from "@/services/expedientesclinicos/Vitola";
import { getFuncions } from "@/services/expedientesclinicos/Funcion";
import { createMensaje } from "@/services/expedientesclinicos/ChatService";
import numeral from "numeral";
import moment from "moment";
// import Pusher from "pusher-js";

export default defineComponent({
  name: "citas-form",
  components: {
    Navbar,
  },
  data() {
    return {
      search: "",
      seguros2: [],
      seguros: [],
      currentEdition: {} as any,
      vitolas: [] as Vitola[],
      empleados: [] as Empleado[],
      empleadosEmp: [] as Empleado[],
      empleadosPeg: [] as Empleado[],
      empleadosMez: [] as Empleado[],
      campoFocus: "fechaActual",
      mensageError: "Error",
      mensageExito: "Cita Registrada Exitosamente",
      mensageConfirm: "¿Está Seguro que desea Eliminar Esta Cita?",
      encabezado: "",
      modoForm: "",
      funciones: [] as Funcion[],
      showDatosPadre: false,
      showDatosMadre: false,
      showDatosTutor: false,
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      cita: {} as Cita,
      oneCita: {} as Cita,
      one: {},
      nextNo: Number,
      medicoSelected: [],
      clienteSelected: [],
      descripcionCliente: "",

      showInComentary: "",
      showModal: false,
      showModal7: false,
      showDelete: false,
      currentConsulta: "",
      estado: {} as object,
    };
  },

  async mounted() {
    if (this.$route.fullPath == "/citas/new") {
      this.modoForm = "add";
      this.encabezado = "Nueva Cita";
    } else {
      this.modoForm = "show";
      this.encabezado = "Detalles de Cita";
    }

    if (this.modoForm == "add") {
      this.cita.no = 1;
      this.cita.consultas = [];
      this.fillFields();
      this.fixTime();
    } else {
      this.showDeleteMethod();
      if (typeof this.$route.params.id === "string") {
        await this.loadCita(this.$route.params.id);
      }
      this.fixTime();
    }

    this.focus();
    this.loadExpedientes();
    // this.loadEmpleados();
    // this.loadLigas();
    // this.loadVitolas();
    // this.filterEmpleados();
  },

  methods: {
    async loadExpedientes() {
      this.toggleLoading();
      try {
        const res = await getExpedientes(this.$store.state.user.puedeVer);
        this.seguros = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },
    setSeguro(seguro: string) {
      this.cita.seguro = seguro;
      this.showModalMethod7();
      this.search = "";
      document.getElementById("oficio").focus();
    },

    buscar(term: string) {
      this.seguros2 = this.seguros;
      if (term !== "") {
        this.seguros2 = this.seguros.filter((seguro: any) => {
          return seguro.nombre.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      if (this.seguros2.length == 1) {
        if (confirm("¿" + this.seguros2[0].nombre + "?")) {
          this.setSeguro(this.seguros2[0].nombre);
          // document.getElementById("oficio").focus();
        }
      }
    },

    showModalMethod7() {
      this.seguros2 = this.seguros;
      this.showModal7 = !this.showModal7;
      // document.getElementById("oficio").focus();
    },

    showModalMethod72() {
      this.cita.seguro = "";
      this.seguros2 = this.seguros;
      this.showModal7 = !this.showModal7;
      // document.getElementById("oficio").focus();
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    deleteService(it: any) {
      if (confirm("Está Seguro que desea Eliminar Esta Consulta?")) {
        this.cita.consultas.splice(it, 1);
      }
    },

    editService(index: number, consulta: any) {
      this.currentEdition = consulta;
      this.showModalMethod();
    },

    newFormatDate(dateValue: Date) {
      // let out = moment(dateValue).add(0, "h");
      // return moment(out).format("DD/MM/YYYY");
      moment.locale("es");
      return moment(dateValue).calendar();
      // .startOf("hour")
      // .fromNow();
    },

    async addActivity() {
      if (this.currentConsulta !== "") {
        this.cita.consultas.push({
          description: this.currentConsulta,
          date: new Date(),
        });
        this.currentConsulta = "";
      }
      document.getElementById("currentConsulta").focus();
    },

    calcularEdad(date: any) {
      let years = 0;
      let edad = Math.floor(
        moment(new Date()).diff(moment(date, "YYYY-MM-DD"), "years", true)
      );
      if (edad > 120 || edad < 0) {
        years = 0;
      } else {
        years = edad;
      }
      this.cita.edad = years;
    },

    async filterEmpleados() {
      this.toggleLoading();
      try {
        const res = await getPeg();
        this.empleadosPeg = res.data;
      } catch (error) {
        // console.error(error);
      }

      try {
        const res = await getEmp();
        this.empleadosEmp = res.data;
      } catch (error) {
        // console.error(error);
      }

      try {
        const res = await getMez();
        this.empleadosMez = res.data;
      } catch (error) {
        // console.error(error);
      }

      this.toggleLoading();
    },

    async loadLigas() {
      // this.toggleLoading();
      try {
        const res = await getLigas();
        this.ligas = res.data;
      } catch (error) {
        // console.error(error);
      }
      // this.toggleLoading();
    },

    async loadVitolas() {
      // this.toggleLoading();
      try {
        const res = await getVitolas();
        this.vitolas = res.data;
      } catch (error) {
        // console.error(error);
      }
      // this.toggleLoading();
    },

    // async loadEmpleados() {
    //   this.toggleLoading();
    //   try {
    //     const res = await getEmpleados();
    //     this.empleados = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   this.toggleLoading();
    // },

    fixTime() {
      this.cita.fecha = this.formatDateToFix(this.cita.fecha, true);
      this.cita.fechaActual = this.formatDateToFix(this.cita.fechaActual, true);
    },

    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DD");
      }
    },

    async loadCita(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getCita(id);
        this.cita = data;
        // this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },

    async handleUpdate() {
      this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.cita.userMod = this.$store.state.user.usuario;
          await updateCita(this.$route.params.id, this.cita);
          this.addMessage();
          this.$router.push("/citas");
        }
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
      // this.toggleAlert();
    },

    async handleDelete() {
      if (confirm(this.mensageConfirm)) {
        try {
          if (typeof this.$route.params.id === "string") {
            await deleteCita(this.$route.params.id);
            this.addMessage();
            this.$router.push("/citas");
          }
        } catch (error) {
          //console.error(error);
        }
      }
    },

    showDeleteMethod() {
      if (this.$store.state.user.type == "Power User") {
        this.showDelete = true;
      }
    },

    toggleShowDatosPadre() {
      this.showDatosPadre = !this.showDatosPadre;
    },
    toggleShowDatosMadre() {
      this.showDatosMadre = !this.showDatosMadre;
    },
    toggleShowDatosTutor() {
      this.showDatosTutor = !this.showDatosTutor;
    },

    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    isError(message: string) {
      if (message == this.mensageExito) {
        return "success";
      } else {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcCita() {
      (this.cita.retension = this.cita.bruto * 0.1),
        (this.cita.neto = this.cita.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fillFields() {
      this.cita.fechaActual = new Date();
      this.cita.fecha = new Date();
    },

    async loadOneCita() {
      try {
        const res = await getOneCita();
        this.oneCita = res.data;
        this.one = this.oneCita[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        if (this.nextNo == null) {
          this.nextNo = 0;
        }
        this.cita.no = this.nextNo;
        this.cita.principal = this.nextNo;
        this.cita.principal = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveCitaa() {
      await this.loadOneCita();
      try {
        const res = await createCitaa(this.servicio);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveCita() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneCita();
          this.oneCita = res.data;
          this.one = this.oneCita[0];
          this.nextNo = this.one.no + 1;
          this.cita.no = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.cita.userReg = this.$store.state.user.usuario;
        const res = await createCita(this.cita).then(
          (res) => {
            this.error = this.respuesta = res.data.title;
            // this.$router.push("/");
            this.res = res;
            this.respuesta = res.data;
            this.addMessage();
          },
          (err) => {
            // console.log(err.response);
            this.error = err.response.data.error;
          }
        );
        // this.$router.push("/citas/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      if (this.error !== this.mensageError) {
        await this.cleanFields();
      }
      await this.fillFields();
      document.getElementById(this.campoFocus).focus();
      this.toggleAlert();
    },

    async deleteAllCitas() {
      try {
        const res = await eliminateCitas(this.cita);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    cleanFields() {
      this.cita.nombre = "";
      this.cita.referidoPor = "";
      this.cita.fechaActual = "";
      this.cita.fechaNacimiento = "";
      this.cita.edad = "";
      this.cita.sexo = "";
      this.cita.cedula = "";
      this.cita.telefono = "";
      this.cita.direccion = "";
      this.cita.seguro = "";
      this.cita.oficio = "";
      this.cita.deporte = "";
      this.cita.ladoDominante = "";
      this.cita.app = "";
      this.cita.habitosToxicos = "";
      this.cita.antQuirurgicos = "";
      this.cita.alergias = "";
      this.cita.mecanismoLesion = "";
      this.cita.tiempoEvolucion = "";
      this.cita.elevacionAnteriorActiva = "";
      this.cita.elevacionAnteriosPasiva = "";
      this.cita.rotacionInterna = "";
      this.cita.rotacionExterna = "";
      this.cita.abd = "";
      this.cita.jobe = "";
      this.cita.speed = "";
      this.cita.obrien = "";
      this.cita.naponeon = "";
      this.cita.acromioClavicular = "";
      this.cita.inestabilidadAnterior = "";
      this.cita.inestabilidadPosterior = "";
      this.cita.cajonAnterior = "";
      this.cita.cajonPosterior = "";
      this.cita.lachman = "";
      this.cita.appley = "";
      this.cita.bostezoLateral = "";
      this.cita.bostezoMedial = "";
      this.cita.otraLesion = "";
      this.cita.estudiosSolicitados = "";
      this.cita.diagnosticoPresuntivo = "";
      this.cita.diagnosticoImagen = "";
      this.cita.medicamentos = "";
      this.cita.terapiaFisica = "";
      this.cita.cirugia = "";
      this.cita.consultas = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById(this.campoFocus).focus();
    },
    focus2() {
      document.getElementById(this.campoFocus2).focus();
    },
  },
});
