
import { loginUsuario } from "@/services/expedientesclinicos/UsuarioService";
import { Usuario } from "@/interfaces/Usuario";
export default {
  name: "LoginView",
  components: {},

  data() {
    return {
      cargando: false,
      error: "",
      usuario: {} as Usuario,
    };
  },

  methods: {
    arreglar() {
      this.usuario.email = this.usuario.email.toLowerCase().trim();
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    // login() {
    //   this.$store.dispatch("loginAction");
    //   this.$store.state.user.type = this.role;
    //   this.$store.state.user.usuario = this.usuario;
    //   this.$router.push("/dashboard");
    // },

    async login() {
      this.toggleLoading();
      // let user = {
      //   email: this.email,
      //   password: this.password,
      // };
      this.arreglar();
      await loginUsuario(this.usuario).then(
        // axios.post("http://localhost:5000/login", user).then(
        (res) => {
          //if successfull
          if (res.status === 200) {
            localStorage.setItem("token", res.data.token);
            //Authenticated True
            this.$store.dispatch("loginAction");
            this.$store.state.user.type = res.data.usuario.role;
            // alert(res.data.usuario.role)
            this.$store.state.user.usuario = res.data.usuario.nombre;
            this.$store.state.user.puedeVer = res.data.usuario.puedeVer;
            this.$store.state.user.defaultStatus =
              res.data.usuario.defaultStatus;
            this.$router.push("/dashboard");
          }
        },
        (err) => {
          // console.log(err.response);
          this.error = err.response.data.error;
        }
      );
      this.toggleLoading();
    },
  },
};
