
import { defineComponent } from "vue";
import { Licencia } from "@/interfaces/Licencia";
import {
  deleteLicencia,
  getLicencia,
  updateLicencia,
} from "@/services/expedientesclinicos/Licencia";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "licencia-list",
  data() {
    return {
      rxs: [
        { titu: "Analgésico", desc: "1 diario x 8 días" },
        { titu: "Antinflamatorio", desc: "2 al día cada 8 hrs x 12 días" },
      ],
      cargando: false,
      licencia: {} as Licencia,
    };
  },
  methods: {
    toPrint() {
      window.print();
    },
    toggleLoading() {
      this.cargando = !this.cargando;
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("D/MM/yyyy HH:mm");
    },

    formatDate2(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("D/MM/yyyy");
    },

    async loadLicencia(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getLicencia(id);
        this.licencia = data;
      } catch (error) {
        //console.error(error);
      }
      await this.toggleLoading();
      this.toPrint();

      if (this.$store.state.ids.length == 0) {
        this.$router.push(`/licencias/${this.licencia._id}`);
      } else {
        this.$router.push(`/licenciaslot/new`);
      }

      // this.$router.push(`/licencias/${this.licencia._id}`);
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateLicencia(this.$route.params.id, this.licencia);
          this.$router.push("/licencias");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteLicencia(this.$route.params.id);
          this.$router.push("/licencias");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadLicencia(this.$route.params.id);
    }
  },

  // beforeUnmount() {
  //   this.toPrint();
  // }
});
