
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Mejora } from "@/interfaces/Mejora";
import {
  createMejora,
  eliminateMejoras,
  createMejoraa,
  getOneMejora,
} from "@/services/expedientesclinicos/MejoraService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "mejoras-form",
  components: {
    Navbar,
  },
  data() {
    return {
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      mejora: {} as Mejora,
      oneMejora: {} as Mejora,
      one: {},
      nextNo: Number,
      medicoSelected: [],
      clienteSelected: [],
      descripcionCliente: "",
    };
  },

  mounted() {
    this.defFields();
    this.focus();
  },

  methods: {
    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcMejora() {
      (this.mejora.retension = this.mejora.bruto * 0.1),
        (this.mejora.neto = this.mejora.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    defFields() {
      this.mejora.estatus = "Pendiente";
    },

    async loadOneMejora() {
      try {
        const res = await getOneMejora();
        this.oneMejora = res.data;
        this.one = this.oneMejora[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        if (this.nextNo == null) {
          this.nextNo = 0;
        }
        this.mejora.no = this.nextNo;
        this.mejora.principal = this.nextNo;
        this.mejora.principal = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveMejoraa() {
      await this.loadOneMejora();
      try {
        const res = await createMejoraa(this.servicio);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveMejora() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneMejora();
          this.oneMejora = res.data;
          this.one = this.oneMejora[0];
          this.nextNo = this.one.no + 1;
          this.mejora.no = this.nextNo;
          this.mejora.principal = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.mejora.userReg = this.$store.state.user.usuario;
        const res = await createMejora(this.mejora);
        // this.$router.push("/mejoras/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      await this.definingFields();
      await this.defFields();
      document.getElementById("title").focus();
      this.toggleAlert();
    },

    async deleteAllMejoras() {
      try {
        const res = await eliminateMejoras(this.mejora);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    definingFields() {
      this.mejora.title = "";
      this.mejora.description = "";
      this.mejora.type = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById("title").focus();
    },
  },
});
