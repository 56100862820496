
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Mejora } from "@/interfaces/Mejora";
import {
  deleteMejora,
  getMejora,
  updateMejora,
} from "@/services/expedientesclinicos/MejoraService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "mejora-list",
  components: {
    Navbar,
  },
  data() {
    return {
      showAlert: false,
      cargando: false,
      clienteSelected: [],
      medicoSelected: [],
      mejora: {} as Mejora,
      estado: {} as object,
    };
  },
  methods: {
    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    async facturar() {
      this.mejora.cantPrefactura = 0;
      this.mejora.cantFactura = 1;
      this.mejora.credito = 0;
      this.mejora.debito = 0;
      this.mejora.subTipo = "Factura";
      this.mejora.factura = this.mejora.prefactura;
      this.mejora.prefactura = 0;
      await this.selectCliente(this.mejora.descCliente);
      this.mejora.literal = this.clienteSelected[0].tipoComprobante.substring(
        0,
        3
      );
      await this.selectMedico(this.mejora.medico);
      if (this.mejora.literal == "B15") {
        this.mejora.ncf = this.medicoSelected[0].compGuberAsig;
        this.mejora.vencimiento = this.medicoSelected[0].fechGuberAsig;
        this.mejora.fechaNCF = new Date();
      } else {
        this.mejora.ncf = this.medicoSelected[0].compNoGuberAsig;
        this.mejora.vencimiento = this.medicoSelected[0].fechNoGuberAsig;
        this.mejora.fechaNCF = new Date();
      }
      this.handleUpdate();
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },

    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fixTime() {
      this.mejora.fechaProceso = this.formatDate(this.mejora.fechaProceso);
      this.mejora.fechaEfectivo = this.formatDate(this.mejora.fechaEfectivo);
      this.mejora.fechaVence = this.formatDate(this.mejora.fechaVence);
    },

    async loadMejora(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getMejora(id);
        this.mejora = data;
        this.estado = this.mejora.estado;
        this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },
    async handleUpdate() {
      this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.mejora.userMod = this.$store.state.user.usuario;
          await updateMejora(this.$route.params.id, this.mejora);
          if (this.mejora.literal == "B15") {
            this.medicoSelected[0].compGuberAsig += 1;
          } else {
            this.medicoSelected[0].compNoGuberAsig += 1;
          }
          this.$router.push("/mejoras");
        }
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
      this.toggleAlert();
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteMejora(this.$route.params.id);
          this.$router.push("/mejoras");
        }
      } catch (error) {
        //console.error(error);
      }
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadMejora(this.$route.params.id);
    }
  },
});
