
import Navbar from "@/components/Navbar.vue";
import { getMejPend } from "@/services/expedientesclinicos/MejoraService";
import { getUsuariosCant } from "@/services/expedientesclinicos/UsuarioService";
import ListadoComponent from "@/components/Expediente/ListComponent.vue";
import {
  getfacturasCant,
  getfactsGpedByCli,
} from "@/services/expedientesclinicos/FacturaService";
import { getGpedByVitola } from "@/services/expedientesclinicos/Rueda";
import { Expediente } from "@/interfaces/Expediente";
import { getExpedientes } from "@/services/expedientesclinicos/Expediente";
import numeral from "numeral";
import Pusher from "pusher-js";
export default {
  name: "DashboardView",
  components: {
    Navbar,
    ListadoComponent,
  },

  data() {
    return {
      gpedByVitola: [] as any,
      usuariosCant: [] as any,
      facturasCant: [] as any,
      factsGpedByCli: [] as any,
      mejorasPend: [] as any,
      totales: {} as any,
      cargando: false,
      expedientes: [] as Expediente[],
      showModal: false,
    };
  },

  methods: {
    showModalMethod() {
      this.showModal = !this.showModal;
    },

    async search(term: string) {
      this.toggleLoading();
      try {
        const res = await getExpedientes(this.$store.state.user.puedeVer);
        this.expedientes = res.data;
      } catch (error) {
        // console.error(error);
      }

      if (term !== "Todos") {
        this.expedientes = this.expedientes.filter((expediente: Expediente) => {
          return expediente.nombre.toLowerCase().includes(term.toLowerCase());
        });
      }
      this.toggleLoading();
      // this.showModalMethod();
    },

    salir() {
      if (confirm("¿Está Seguro que desea Cerrar Sesión?")) {
        this.$router.push("/");
        this.$store.dispatch("logoutAction");
      }
    },

    hamburger(texto: string) {
      switch (texto) {
        case "Facturas":
          this.$router.push("/facturaas");
          break;
        case "Reclamos":
          this.$router.push("/reclamos");
          break;
        case "Lotes":
          this.$router.push("/lotes");
          break;
        case "Resclinicos":
          this.$router.push("/resclinicos");
          break;
        case "Licencias":
          this.$router.push("/licencias");
          break;
        case "Recetas":
          this.$router.push("/recetas");
          break;
        case "Citas":
          this.$router.push("/citas");
          break;
        case "Pacientes":
          this.$router.push("/pacientes");
          break;
        case "Expedientes":
          this.$router.push("/expedientes");
          break;
        case "Ruedas":
          this.$router.push("/ruedas");
          break;
        case "Cxp":
          this.$router.push("/cxps");
          break;
        case "Ligas":
          this.$router.push("/ligas");
          break;
        case "Empleados":
          this.$router.push("/empleados");
          break;
        case "Funciones":
          this.$router.push("/funcions");
          break;
        case "Mejoras":
          this.$router.push("/mejoras");
          break;
        case "Usuarios":
          this.$router.push("/usuarios");
          break;
        case "Dashboard":
          this.$router.push("/dashboard");
          break;
        case "Vitolas":
          this.$router.push("/vitolas");
          break;
        case "Cerrar Sesión":
          this.salir();
          break;
      }
      if (this.navMenuClas.length == 1) {
        this.navMenuClas.push("spread");
      } else {
        this.navMenuClas.pop();
      }
    },

    getARS(id_ars: string) {
      switch (id_ars) {
        case "5":
          return "ARS CMD";
        case "14":
          return "MAPFRE SALUD ARS, S.A.";
        case "8":
          return "FUTURO";
        case "12":
          return "META SALUD";
        case "23":
          return "YUNEN";
        case "17":
          return "RESERVAS";
        case "13":
          return "MONUMENTAL";
        case "4":
          return "ASEMAP";
        case "1":
          return "ARS APS S A";
        case "21":
          return "SIMAG";
        case "16":
          return "RENACER";
        case "59":
          return "GRUPO MEDICO ASOCIADO";
        case "24":
          return "PRIMERA  ARS DE HUMANO";
        case "22":
          return "UNIVERSAL";
        case "29":
          return "ALBA GAS S.R.L.";
        case "10":
          return "HUMANO SEGUROS";
        case "20":
          return "SENASA CONTRIBUTIVO";
        case "61":
          return "SENASA SUBSIDIADO";
        case "18":
          return "SEMMA";
        case "65":
          return "IDOPPRIL";
        default:
          return "ARS Descripcion";
      }
    },

    toColor(type: string) {
      if (type == "1 - Recibido por Auditoría Interna") {
        return "valor1";
      } else if (type == "2 - Verificado por Auditoría Interna") {
        return "valor2";
      } else if (type == "3 - Verificado por Auditoría Externa") {
        return "valor3";
      } else if (type == "4 - Recibido por Reclamaciones Médicas") {
        return "valor4";
      } else if (type == "5 - Verificado por Reclamaciones Médicas") {
        return "valor5";
      } else if (type == "6 - Cargado a Lote") {
        return "valor6";
      } else if (type == "7 - Enviado a la Aseguradora") {
        return "valor7";
      } else if (type == "Todos") {
        return "Todos";
      }
    },

    valorTotal() {
      this.totales.ruedas = this.gpedByVitola.reduce(
        (accum: any, item: any) => accum + item.count,
        0
      );

      this.totales.cantidad = this.gpedByVitola.reduce(
        (accum: any, item: any) => accum + item.cantidad,
        0
      );

      this.totales.gpedByVitola = this.gpedByVitola.length;
    },

    pusherSubscribe() {
      // Start pusher subscribe
      var pusher = new Pusher("d7b50b87118775ed0b11", {
        cluster: "us2",
      });

      var channel = pusher.subscribe("my-channel");
      channel.bind("my-event", (data: any) => {
        this.loadfacturasCant2();
        this.loadfactsGpedByCli2();
        this.loadGpedByVitola();
        // this.player.src = this.song.src;
        // this.player.play();
      });
      // End pusher subscribe
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    formatNumber(value: number, decimal: boolean) {
      if (decimal == true) {
        return numeral(value).format("0,0.00");
      } else {
        return numeral(value).format("0,0");
      }
    },

    async loadMejorasPendientes() {
      // this.toggleLoading();
      try {
        const res = await getMejPend();
        this.mejorasPend = res.data;
      } catch (error) {
        // console.error(error);
      }
      // this.toggleLoading();
    },

    async loadUsuariosCant() {
      // this.toggleLoading();
      try {
        const res = await getUsuariosCant();
        this.usuariosCant = res.data;
      } catch (error) {
        // console.error(error);
      }
      // this.toggleLoading();
    },

    async loadfacturasCant() {
      this.toggleLoading();
      try {
        const res = await getfacturasCant();
        this.facturasCant = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadGpedByVitola() {
      // this.toggleLoading();
      try {
        const res = await getGpedByVitola();
        this.gpedByVitola = res.data;
      } catch (error) {
        // console.error(error);
      }
      // this.toggleLoading();
    },

    async loadfactsGpedByCli() {
      this.toggleLoading();
      try {
        const res = await getfactsGpedByCli();
        this.factsGpedByCli = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadfactsGpedByCli2() {
      try {
        const res = await getfactsGpedByCli();
        this.factsGpedByCli = res.data;
      } catch (error) {
        // console.error(error);
      }
    },

    async loadfacturasCant2() {
      // this.toggleLoading();
      try {
        const res = await getfacturasCant();
        this.facturasCant = res.data;
      } catch (error) {
        // console.error(error);
      }
      // this.toggleLoading();
    },
  },

  mounted() {
    this.loadMejorasPendientes();
    this.loadUsuariosCant();
    this.loadGpedByVitola();
    // this.loadfacturasCant();
    // this.loadfactsGpedByCli();
    this.pusherSubscribe();
  },

  updated() {
    this.valorTotal();
  },
};
