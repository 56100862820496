
import { getExpedientes } from "@/services/expedientesclinicos/Expediente";
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Resclinico } from "@/interfaces/Resclinico";
import { Funcion } from "@/interfaces/Funcion";
import { Vitola } from "@/interfaces/Vitola";
import { Empleado } from "@/interfaces/Empleado";
import {
  getPeg,
  getMez,
  getEmp,
} from "@/services/expedientesclinicos/Empleado";
import { getLigas } from "@/services/expedientesclinicos/Liga";
import {
  createResclinico,
  eliminateResclinicos,
  createResclinicoa,
  getOneResclinico,
  deleteResclinico,
  getResclinico,
  updateResclinico,
  getPrint,
} from "@/services/consultasdrmorales/Resclinico";
import { getVitolas } from "@/services/expedientesclinicos/Vitola";
import { getFuncions } from "@/services/expedientesclinicos/Funcion";
import { createMensaje } from "@/services/expedientesclinicos/ChatService";
import numeral from "numeral";
import moment from "moment";
// import Pusher from "pusher-js";

export default defineComponent({
  name: "resclinicos-form",
  components: {
    Navbar,
  },
  data() {
    return {
      search: "",
      seguros2: [],
      seguros: [
        "NINGUNO",
        "ARS CMD",
        "MAPFRE SALUD ARS, S.A.",
        "FUTURO",
        "META SALUD",
        "YUNEN",
        "RESERVAS",
        "MONUMENTAL",
        "ASEMAP",
        "ARS APS S A",
        "SIMAG",
        "RENACER",
        "GRUPO MEDICO ASOCIADO",
        "PRIMERA ARS DE HUMANO",
        "UNIVERSAL",
        "HUMANO SEGUROS",
        "SENASA CONTRIBUTIVO",
        "SENASA SUBSIDIADO",
        "SEMMA",
        "IDOPPRIL",
        "ARS UASD",
        "OTRA",
      ],
      currentEdition: {} as any,
      vitolas: [] as Vitola[],
      empleados: [] as Empleado[],
      empleadosEmp: [] as Empleado[],
      empleadosPeg: [] as Empleado[],
      empleadosMez: [] as Empleado[],
      campoFocus: "resclinico",
      mensageError: "Error",
      mensageExito: "Resclinico Registrada Exitosamente",
      mensageConfirm: "¿Está Seguro que desea Eliminar Esta Resclinico?",
      mensageConfirmRenew: "¿Está Seguro que desea Renovar Esta Resclinico?",
      encabezado: "",
      modoForm: "",
      funciones: [] as Funcion[],
      showDatosPadre: false,
      showDatosMadre: false,
      showDatosTutor: false,
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      resclinico: {} as Resclinico,
      oneResclinico: {} as Resclinico,
      one: {},
      nextNo: Number,
      medicoSelected: [],
      clienteSelected: [],
      descripcionCliente: "",

      showInComentary: "",
      showModal: false,
      showModal7: false,
      showDelete: false,
      currentConsulta: "",
      estado: {} as object,
      printId: {} as any,
    };
  },

  async mounted() {
    // this.$store.state.user.crrntPcte = "YULISKA ALTAGRACIA CANERA";
    if (this.$route.fullPath == "/resclinicos/new") {
      this.modoForm = "add";
      this.encabezado = "Nuevo Resumen Clínico";
    } else {
      this.modoForm = "show";
      this.encabezado = "Detalles de Resumen Clínico";
    }

    if (this.modoForm == "add") {
      // this.resclinico.no = 1;
      // this.resclinico.consultas = [];
      this.fillFields();
      this.fixTimeAdd();
    } else {
      this.showDeleteMethod();
      if (typeof this.$route.params.id === "string") {
        await this.loadResclinico(this.$route.params.id);
      }
      this.fixTime();
    }

    this.focus();
    this.loadExpedientes();
    // this.loadEmpleados();
    // this.loadLigas();
    // this.loadVitolas();
    // this.filterEmpleados();
  },

  methods: {
    newResumen() {
      this.$router.push(`/resclinicos/new`);
      this.showDelete = false;
      this.resclinico.contenido = "";
      this.modoForm = "add";
      this.encabezado = "Nuevo Resumen";
      document.getElementById("resclinico").focus();
    },
    async loadExpedientes() {
      this.toggleLoading();
      try {
        const res = await getExpedientes(this.$store.state.user.puedeVer);
        this.seguros = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    setSeguro(seguro: string, cedula: string, edad: number) {
      this.resclinico.paciente = seguro;
      this.resclinico.edad = edad;
      this.resclinico.identificacion = cedula;

      // this.resclinico.contenido = `Por medio de la presente hacemos constar que el/la paciente ${this.resclinico.nombre}, identificación No. ${this.resclinico.seguro}, presenta el/los Diagnóstico(s) de __________________________, por lo que recomendamos _____________________________, a los fines de contribuir con su recuperación.`;

      this.showModalMethod7();
      this.search = "";
      document.getElementById("resclinico").focus();
    },

    buscar(term: string) {
      this.seguros2 = this.seguros;
      if (term !== "") {
        this.seguros2 = this.seguros.filter((seguro: any) => {
          return seguro.nombre
            .toLowerCase()
            .includes(this.search.toLowerCase());
        });
      }
      if (this.seguros2.length == 1) {
        if (confirm("¿" + this.seguros2[0].nombre + "?")) {
          this.setSeguro(this.seguros2[0].nombre, this.seguros2[0].edad);
          // document.getElementById("oficio").focus();
        }
      }
    },

    showModalMethod7() {
      this.seguros2 = this.seguros;
      this.showModal7 = !this.showModal7;
      // document.getElementById("oficio").focus();
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    deleteService(it: any) {
      if (confirm("Está Seguro que desea Eliminar Esta Consulta?")) {
        this.resclinico.consultas.splice(it, 1);
      }
    },

    editService(index: number, consulta: any) {
      this.currentEdition = consulta;
      this.showModalMethod();
    },

    newFormatDate(dateValue: Date) {
      // let out = moment(dateValue).add(0, "h");
      // return moment(out).format("DD/MM/YYYY");
      moment.locale("es");
      return moment(dateValue).calendar();
      // .startOf("hour")
      // .fromNow();
    },

    async addActivity() {
      if (this.currentConsulta !== "") {
        this.resclinico.consultas.push({
          description: this.currentConsulta,
          date: new Date(),
        });
        this.currentConsulta = "";
      }
      document.getElementById("resclinico").focus();
    },

    calcularEdad(date: any) {
      let years = 0;
      let edad = Math.floor(
        moment(new Date()).diff(moment(date, "YYYY-MM-DD"), "years", true)
      );
      if (edad > 120 || edad < 0) {
        years = 0;
      } else {
        years = edad;
      }
      this.resclinico.edad = years;
    },

    async filterEmpleados() {
      this.toggleLoading();
      try {
        const res = await getPeg();
        this.empleadosPeg = res.data;
      } catch (error) {
        // console.error(error);
      }

      try {
        const res = await getEmp();
        this.empleadosEmp = res.data;
      } catch (error) {
        // console.error(error);
      }

      try {
        const res = await getMez();
        this.empleadosMez = res.data;
      } catch (error) {
        // console.error(error);
      }

      this.toggleLoading();
    },

    async loadLigas() {
      // this.toggleLoading();
      try {
        const res = await getLigas();
        this.ligas = res.data;
      } catch (error) {
        // console.error(error);
      }
      // this.toggleLoading();
    },

    async loadVitolas() {
      // this.toggleLoading();
      try {
        const res = await getVitolas();
        this.vitolas = res.data;
      } catch (error) {
        // console.error(error);
      }
      // this.toggleLoading();
    },

    // async loadEmpleados() {
    //   this.toggleLoading();
    //   try {
    //     const res = await getEmpleados();
    //     this.empleados = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   this.toggleLoading();
    // },

    fixTimeAdd() {
      this.resclinico.fechaActual = this.formatDateToFixAdd(
        this.resclinico.fechaActual,
        true
      );
    },

    fixTime() {
      this.resclinico.fecha = this.formatDateToFix(this.resclinico.fecha, true);
      this.resclinico.fechaActual = this.formatDateToFix(
        this.resclinico.fechaActual,
        true
      );
    },

    formatDateToFixAdd(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "h");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DD");
      }
    },

    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "h");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DD");
      }
    },

    async loadResclinico(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getResclinico(id);
        this.resclinico = data;
        // this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },

    async handleUpdate() {
      this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          // this.resclinico.userMod = this.$store.state.user.usuario;
          await updateResclinico(this.$route.params.id, this.resclinico);
          this.addMessage();
          this.$router.push("/resclinicos");
        }
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
      // this.toggleAlert();
    },

    async handleRenew() {
      if (confirm(this.mensageConfirmRenew)) {
        try {
          await this.fillFields();
          await this.saveResclinico();
          this.addMessage();
          // this.$router.push("/resclinicos");
        } catch (error) {
          //console.error(error);
        }
      }
    },

    async handleDelete() {
      if (confirm(this.mensageConfirm)) {
        try {
          if (typeof this.$route.params.id === "string") {
            await deleteResclinico(this.$route.params.id);
            this.addMessage();
            this.$router.push("/resclinicos");
          }
        } catch (error) {
          //console.error(error);
        }
      }
    },

    showDeleteMethod() {
      if (this.$store.state.user.type == "Power User") {
        this.showDelete = true;
      }
    },

    toggleShowDatosPadre() {
      this.showDatosPadre = !this.showDatosPadre;
    },
    toggleShowDatosMadre() {
      this.showDatosMadre = !this.showDatosMadre;
    },
    toggleShowDatosTutor() {
      this.showDatosTutor = !this.showDatosTutor;
    },

    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    isError(message: string) {
      if (message == this.mensageExito) {
        return "success";
      } else {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcResclinico() {
      (this.resclinico.retension = this.resclinico.bruto * 0.1),
        (this.resclinico.neto = this.resclinico.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fillFields() {
      this.resclinico.fechaActual = new Date();
      this.resclinico.fecha = new Date();
    },

    async loadOneResclinico() {
      try {
        const res = await getOneResclinico();
        this.oneResclinico = res.data;
        this.one = this.oneResclinico[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        if (this.nextNo == null) {
          this.nextNo = 0;
        }
        this.resclinico.no = this.nextNo;
        this.resclinico.principal = this.nextNo;
        this.resclinico.principal = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async loadPrint() {
      try {
        const res = await getPrint();
        this.printId = res.data;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveResclinicoa() {
      // await this.loadOneResclinico();
      try {
        const res = await createResclinicoa(this.servicio);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveResclinico() {
      this.toggleLoading();
      try {
        // try {
        //   const res = await getOneResclinico();
        //   this.oneResclinico = res.data;
        //   this.one = this.oneResclinico[0];
        //   this.nextNo = this.one.no + 1;
        //   this.resclinico.no = this.nextNo;
        // } catch (error) {
        //   // // console.error(error);
        // }
        // this.resclinico.userReg = this.$store.state.user.usuario;
        const res = await createResclinico(this.resclinico).then(
          (res) => {
            this.error = this.respuesta = res.data.title;
            // this.$router.push("/");
            this.res = res;
            this.respuesta = res.data;
            this.addMessage();
            // alert(JSON.stringify(this.respuesta.Item));
          },
          (err) => {
            // console.log(err.response);
            this.error = err.response.data.error;
          }
        );
        // this.$router.push("/resclinicos/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      if (this.error !== this.mensageError) {
        await this.cleanFields();
      }
      await this.fillFields();
      document.getElementById(this.campoFocus).focus();
      this.toggleAlert();
      // Impresion Directa
      // await this.loadPrint();
      this.$router.push(`/resclinicos2/${this.respuesta.Item.id}`);
    },

    async deleteAllResclinicos() {
      try {
        const res = await eliminateResclinicos(this.resclinico);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    cleanFields() {
      this.resclinico.nombre = "";
      this.resclinico.referidoPor = "";
      this.resclinico.fechaActual = "";
      this.resclinico.fechaNacimiento = "";
      this.resclinico.edad = "";
      this.resclinico.sexo = "";
      this.resclinico.cedula = "";
      this.resclinico.telefono = "";
      this.resclinico.direccion = "";
      this.resclinico.seguro = "";
      this.resclinico.oficio = "";
      this.resclinico.deporte = "";
      this.resclinico.ladoDominante = "";
      this.resclinico.app = "";
      this.resclinico.habitosToxicos = "";
      this.resclinico.antQuirurgicos = "";
      this.resclinico.alergias = "";
      this.resclinico.mecanismoLesion = "";
      this.resclinico.tiempoEvolucion = "";
      this.resclinico.elevacionAnteriorActiva = "";
      this.resclinico.elevacionAnteriosPasiva = "";
      this.resclinico.rotacionInterna = "";
      this.resclinico.rotacionExterna = "";
      this.resclinico.abd = "";
      this.resclinico.jobe = "";
      this.resclinico.speed = "";
      this.resclinico.obrien = "";
      this.resclinico.naponeon = "";
      this.resclinico.acromioClavicular = "";
      this.resclinico.inestabilidadAnterior = "";
      this.resclinico.inestabilidadPosterior = "";
      this.resclinico.cajonAnterior = "";
      this.resclinico.cajonPosterior = "";
      this.resclinico.lachman = "";
      this.resclinico.appley = "";
      this.resclinico.bostezoLateral = "";
      this.resclinico.bostezoMedial = "";
      this.resclinico.otraLesion = "";
      this.resclinico.estudiosSolicitados = "";
      this.resclinico.diagnosticoPresuntivo = "";
      this.resclinico.diagnosticoImagen = "";
      this.resclinico.medicamentos = "";
      this.resclinico.terapiaFisica = "";
      this.resclinico.cirugia = "";
      this.resclinico.consultas = "";
      this.resclinico.contenido = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById(this.campoFocus).focus();
    },
    focus2() {
      document.getElementById(this.campoFocus2).focus();
    },
  },
});
