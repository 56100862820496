
import { defineComponent } from "vue";
import { Rueda } from "@/interfaces/Rueda";
import { deleteRueda, getRueda, updateRueda } from "@/services/expedientesclinicos/Rueda";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "rueda-list",
  data() {
    return {
      cargando: false,
      rueda: {} as Rueda,
    };
  },
  methods: {
    toPrint() {
      window.print();
    },
    toggleLoading() {
      this.cargando = !this.cargando;
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("D/MM/yyyy HH:mm");
    },
    async loadRueda(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getRueda(id);
        this.rueda = data;
      } catch (error) {
        //console.error(error);
      }
      await this.toggleLoading();
      this.toPrint();

      if (this.$store.state.ids.length == 0) {
        this.$router.push(`/ruedas/${this.rueda._id}`);
      } else {
        this.$router.push(`/ruedaslot/new`);
      }

      // this.$router.push(`/ruedas/${this.rueda._id}`);
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateRueda(this.$route.params.id, this.rueda);
          this.$router.push("/ruedas");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteRueda(this.$route.params.id);
          this.$router.push("/ruedas");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadRueda(this.$route.params.id);
    }
  },

  // beforeUnmount() {
  //   this.toPrint();
  // }
});
