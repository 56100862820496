
import { defineComponent } from "vue";
import { Resclinico } from "@/interfaces/Resclinico";
import {
  deleteResclinico,
  getResclinico,
  updateResclinico,
} from "@/services/consultasdrmorales/Resclinico";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "resclinico-list",
  data() {
    return {
      rxs: [
        { titu: "Analgésico", desc: "1 diario x 8 días" },
        { titu: "Antinflamatorio", desc: "2 al día cada 8 hrs x 12 días" },
      ],
      cargando: false,
      resclinico: {} as Resclinico,
    };
  },
  methods: {
    toPrint() {
      window.print();
    },
    toggleLoading() {
      this.cargando = !this.cargando;
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("D/MM/yyyy HH:mm");
    },

    formatDate2(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("D/MM/yyyy");
    },

    async loadResclinico(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getResclinico(id);
        this.resclinico = data;
      } catch (error) {
        //console.error(error);
      }
      await this.toggleLoading();
      this.toPrint();

      if (this.$store.state.ids.length == 0) {
        this.$router.push(`/resclinicos/${this.resclinico.id}`);
      } else {
        this.$router.push(`/resclinicoslot/new`);
      }

      // this.$router.push(`/resclinicos/${this.resclinico._id}`);
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateResclinico(this.$route.params.id, this.resclinico);
          this.$router.push("/resclinicos");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteResclinico(this.$route.params.id);
          this.$router.push("/resclinicos");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadResclinico(this.$route.params.id);
    }
  },

  // beforeUnmount() {
  //   this.toPrint();
  // }
});
