
import Filters from "@/components/Area/FiltersComponent.vue";
import ListadoComponent from "@/components/Area/ListComponent.vue";
import Navbar from "@/components/Navbar.vue";
import { Area } from "@/interfaces/Area";
import { getAreas } from "@/services/expedientesclinicos/AreaService";

export default {
  name: "App",

  components: {
    Filters,
    ListadoComponent,
    Navbar,
  },

  data() {
    return {
      cargando: false,
      areas: [] as Area[],
      str: "",
      type: "",
    };
  },

  methods: {
    toggleLoading() {
      this.cargando = !this.cargando;
    },

    async filterAreas(catName: string) {
      try {
        const res = await getAreas();
        this.areas = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (catName !== "Todos") {
        this.areas = this.areas.filter((area: Area) => {
          return area.type === catName;
        });
      }
    },

    async search(term: string) {
      this.toggleLoading();
      try {
        const res = await getAreas();
        this.areas = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (term !== "Todos") {
        this.areas = this.areas.filter((area: Area) => {
          return area.description.toLowerCase().includes(term.toLowerCase());
        });
      }
      this.toggleLoading();
    },

    async loadAreas() {
      this.toggleLoading();
      try {
        const res = await getAreas();
        this.areas = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },
  },

  mounted() {
    this.loadAreas();
  },
};
