
import Navbar from "@/components/Navbar.vue";
import { createMensaje } from "@/services/expedientesclinicos/ChatService";
import Pusher from "pusher-js";

export default {
  name: "App",

  components: {
    // Filters,
    Navbar,
  },

  data() {
    return {
      messages: [] as any,
      message: {},
    };
  },

  methods: {
    async addMessage() {
      try {
        const res = await createMensaje(this.message);
        // this.messages.push("Cómo Estás?");
      } catch (error) {
        // console.error(error);
      }
    },
  },

  mounted() {
    // Start pusher subscribe
    var pusher = new Pusher("d7b50b87118775ed0b11", {
      cluster: "us2",
    });

    var channel = pusher.subscribe("my-channel");
    channel.bind("my-event", (data: any) => {
      this.messages.push(JSON.stringify(data));
    });
    // End pusher subscribe
  },
};
