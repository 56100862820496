
import { defineComponent } from "vue";

export default defineComponent({
  name: "Nav-bar",

  data() {
    return {
      showMenu: false,
      showName: false,
      showFactura: false,
      navMenuClas: ["menu-navegacion"],
    };
  },

  methods: {
    hamburger(texto: string) {
      switch (texto) {
        case "Facturas":
          this.$router.push("/facturaas");
          break;
        case "Lotes":
          this.$router.push("/lotes");
          break;
        case "Reclamos":
          this.$router.push("/reclamos");
          break;
        case "Licencias":
          this.$router.push("/licencias");
          break;
        case "Resclinicos":
          this.$router.push("/resclinicos");
          break;
        case "Recetas":
          this.$router.push("/recetas");
          break;
        case "Citas":
          this.$router.push("/citas");
          break;
        case "Pacientes":
          this.$router.push("/pacientes");
          break;
        case "Expedientes":
          this.$router.push("/expedientes");
          break;
        case "Ruedas":
          this.$router.push("/ruedas");
          break;
        case "Cxp":
          this.$router.push("/cxps");
          break;
        case "Ligas":
          this.$router.push("/ligas");
          break;
        case "Empleados":
          this.$router.push("/empleados");
          break;
        case "Funciones":
          this.$router.push("/funcions");
          break;
        case "Mejoras":
          this.$router.push("/mejoras");
          break;
        case "Usuarios":
          this.$router.push("/usuarios");
          break;
        case "Dashboard":
          this.$router.push("/dashboard");
          break;
        case "Vitolas":
          this.$router.push("/vitolas");
          break;
        case "Cerrar Sesión":
          this.salir();
          break;
      }
      if (this.navMenuClas.length == 1) {
        this.navMenuClas.push("spread");
      } else {
        this.navMenuClas.pop();
      }
    },

    tglMenu() {
      this.showMenu = !this.showMenu;
    },

    tshowFactura() {
      (this.showName = false), (this.showFactura = !this.showFactura);
    },

    tglName() {
      this.showName = !this.showName;
      this.showFactura = false;
    },

    salir() {
      if (confirm("¿Está Seguro que desea Cerrar Sesión?")) {
        this.$router.push("/");
        this.$store.dispatch("logoutAction");
      }
    },
  },
});
