
import { defineComponent } from "vue";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "lotes-form",
  components: {},
  data() {
    return {
      trStyle: "color: rgb(217, 217, 217); padding:0; margin: 0;",
      trStyle1: "padding:0; margin: 0;"
    };
  },

  async mounted() {
    this.fixTime();
    // this.toPrint();
    // this.$router.push(`/facturaas/new`);
  },

  methods: {
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    fixTime() {
      this.$store.state.user.factura.fechaEmision = this.formatDateToFix(
        this.$store.state.user.factura.fechaEmision,
        false
      );

      this.toPrint();
      this.$router.push(`/facturaas/new`);

      // if (this.factura.venceNcf) {
      //   this.factura.venceNcf = this.formatDateToFix(
      //     this.factura.venceNcf,
      //     false
      //   );
      // }
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },

    toPrint() {
      window.print();
    },

    newFormatDate(dateValue: Date) {
      // let out = moment(dateValue).add(0, "h");
      // return moment(out).format("DD/MM/YYYY");
      moment.locale("es");
      return moment(dateValue).calendar();
      // .startOf("hour")
      // .fromNow();
    },

    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        // alert("formatting");
        let out = moment(dateValue).add(0, "hours");
        return moment(out).format("D-MMMM-YYYY");
      }
    },

    formatDateText(dateValue: Date) {
      let out = moment(dateValue).add(0, "hours");
      return moment(out).format("MMMM Do YYYY");
    },

    formatDate2(dateValue: Date) {
      let out = moment(dateValue).add(0, "hours");
      return moment(out).format("DD MM YYYY");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(4, "hours");
      return moment(out).format("DD/MM/YYYY");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },
  },
});
