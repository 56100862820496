
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Facturaa } from "@/interfaces/Facturaa";
// import { Funcion } from "@/interfaces/Funcion";
// import { Vitola } from "@/interfaces/Vitola";
// import { Facturaa } from "@/interfaces/Facturaa";
// import {
//   getPeg,
//   getMez,
//   getEmp,
// } from "@/services/expedientesclinicos/Facturaa";
// import { getLigas } from "@/services/expedientesclinicos/Liga";
import {
  createFacturaa,
  eliminateFacturaas,
  createFacturaaa,
  getOneFacturaa,
  deleteFacturaa,
  getFacturaa,
  updateFacturaa,
} from "@/services/expedientesclinicos/Facturaa";
import { GetByAflNSS } from "@/services/expedientesclinicos/Expediente";
// import { getVitolas } from "@/services/expedientesclinicos/Vitola";
// import { getFuncions } from "@/services/expedientesclinicos/Funcion";
import { createMensaje } from "@/services/expedientesclinicos/ChatService";
import { getFacturaas } from "@/services/expedientesclinicos/Facturaa";
import numeral from "numeral";
import moment from "moment";
// import Pusher from "pusher-js";

export default defineComponent({
  name: "facturaas-form",
  components: {
    Navbar,
  },
  data() {
    return {
      facturaas: [] as Facturaa[],
      subs: [],
      preSubs: [],
      almacenes: [
        {
          desc: "Almacén No. 01 (General)",
          subs: ["Donaciones", "Venta / Servicios", "Muestras Médicas"],
        },
        {
          desc: "Almacén No. 02 (Suministro de Oficina y Limpieza)",
          subs: [
            "Sub-01 (Materiales de Oficina)",
            "Sub-02 (Materiales de Limpieza)",
          ],
        },
        {
          desc: "Almacén No. 03 (Abastecimiento)",
          subs: [
            "Sub-01 (Carro de Paro UCI)",
            "Sub-02 (Carro de Paro 2do Piso)",
            "Sub-03 (Carro de Paro Perinato)",
            "Sub-04 (Carro de Paro Quirófano)",
            "Sub-05 (Carro de Paro 3er Piso)",
          ],
        },
        {
          desc: "Almacén No. 04 (Emergencia, Quirófano y Ambulatorio)",
          subs: [
            "Sub-01 (Emergencia)",
            "Sub-02 (Depósito Quirófano)",
            "Sub-03 (Imágenes)",
            "Sub-04 (Laboratorio Clínico)",
            "Sub-05 (Laboratorio Biomolecular)",
            "Sub-06 (Farmacia 3er Piso)",
            "Sub-07 (Emergencia Pediatrica)",
          ],
        },
        {
          desc: "Almacén No. 70 (Cocina)",
          subs: [
            "Sub-01 (Alimentos)",
            "Sub-02 (Bebidas y Líquidos)",
            "Sub-03 (Suministros)",
            "Sub-04 (Utencilios)",
          ],
        },
        {
          desc: "Almacén No. 98 (Vencidos)",
          subs: [],
        },
        {
          desc: "Almacén No. 99 (Averiado)",
          subs: [],
        },
      ],
      num1: 0,
      num2: 0,
      fechaActual: "",
      search: "",
      seguros2: [],
      seguros: [
        "NINGUNO",
        "ARS CMD",
        "MAPFRE SALUD ARS, S.A.",
        "FUTURO",
        "META SALUD",
        "YUNEN",
        "RESERVAS",
        "MONUMENTAL",
        "ASEMAP",
        "ARS APS S A",
        "SIMAG",
        "RENACER",
        "GRUPO MEDICO ASOCIADO",
        "PRIMERA ARS DE HUMANO",
        "UNIVERSAL",
        "HUMANO SEGUROS",
        "SENASA CONTRIBUTIVO",
        "SENASA SUBSIDIADO",
        "SEMMA",
        "IDOPPRIL",
        "ARS UASD",
        "OTRA",
      ],
      currentEdition: {} as any,
      // vitolas: [] as Vitola[],
      // facturaas: [] as Facturaa[],
      // facturaasEmp: [] as Facturaa[],
      // facturaasPeg: [] as Facturaa[],
      // facturaasMez: [] as Facturaa[],
      campoFocus: "aflNSS",
      mensageError:
        "Ya Existe un Facturaa Registrado con este Número de Autorización",
      mensageExito: "Facturaa Registrado Exitosamente",
      mensageConfirm: "¿Está Seguro que desea Eliminar Este Facturaa?",
      encabezado: "",
      modoForm: "",
      // funciones: [] as Funcion[],
      showDatosPadre: false,
      showDatosMadre: false,
      showDatosTutor: false,
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      showMessage: false,
      loadedAfiliado: {},
      cargando: false,
      showSpinner: false,
      facturaa: {} as Facturaa,
      oneFacturaa: {} as Facturaa,
      one: {},
      nextNo: Number,
      facturaaSelected: [],
      clienteSelected: [],
      descripcionCliente: "",

      showInComentary: "",
      showModal: false,
      showModal7: false,
      showModalAseguradoras: false,
      showDelete: false,
      currentConsulta: "",
      estado: {} as object,
    };
  },

  async mounted() {
    // this.fechaActual = new Date();
    // this.loadFacturaas();
    // this.seguros = [
    //   "Victor Osiris Alcántara Castro",
    //   "Abel David Ventura Rivas",
    // ];
    if (this.$route.fullPath == "/facturaasciru/new") {
      this.modoForm = "add";
      this.encabezado = "Nueva Reclamación por Cirugía";
    } else {
      this.modoForm = "show";
      this.encabezado = "Detalles de Reclamación por Cirugía";
    }

    if (this.modoForm == "add") {
      this.facturaa.no = 1;
      this.facturaa.consultas = [];
      this.fillFields();
      this.fixTime();
    } else {
      this.showDeleteMethod();
      if (typeof this.$route.params.id === "string") {
        await this.loadFacturaa(this.$route.params.id);
        // this.defSubs();

        // this.facturaa.finVac = new Date();
        // this.facturaa.fechaNac = new Date();
        // this.facturaa.fechaCont = new Date();
      }
      this.fixTime();
    }

    this.focus();
    // this.loadFacturaas();
    // this.loadLigas();
    // this.loadVitolas();
    // this.filterFacturaas();
  },

  methods: {
    showMessageMethod() {
      this.showMessage = !this.showMessage;
    },
    setService() {
      if (this.facturaa.tipServ == "Consulta") {
        this.facturaa.descServ = "Consulta";
      } else {
        this.facturaa.descServ = "";
      }
    },

    showModalAseguradorasMethod() {
      this.seguros2 = this.seguros;
      this.showModalAseguradoras = !this.showModalAseguradoras;
    },

    async redirect(id: string) {
      // alert("Redirecting");
      this.$router.push(`/facturaas/${id}`);

      // Remounted
      this.modoForm = "show";
      this.encabezado = "Detalles de Facturaa";
      this.showDeleteMethod();
      await this.loadFacturaa(id);
      this.defSubs();

      // this.facturaa.finVac = new Date();
      // this.facturaa.fechaNac = new Date();
      // this.facturaa.fechaCont = new Date();
      this.fixTime();
      this.focus();

      this.showModalMethod7();
    },

    async buscando() {
      this.toggleLoading();
      try {
        const res = await getFacturaas(this.criterio);
        this.facturaas = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (this.facturaa.barcode !== "Todos") {
        this.facturaas = this.facturaas.filter((facturaa: Facturaa) => {
          return facturaa.nombPac
            .toLowerCase()
            .includes(this.facturaa.barcode.toLowerCase());
        });
      }
      this.toggleLoading();
    },

    async changeFocus() {
      document.getElementById("fechAutor").focus();
      await this.buscando();
      this.showModalMethod7();
    },
    calcVenc(date: any) {
      this.facturaa.mesVenc = moment(date).format("MM");
      this.facturaa.anoVenc = moment(date).format("YYYY");
    },

    // defSubs() {
    //   this.preSubs = this.almacenes.filter((almacen: Facturaa) => {
    //     return almacen.desc
    //       .toLowerCase()
    //       .includes(this.facturaa.almacn.toLowerCase());
    //   });
    //   this.subs = this.preSubs[0].subs;
    // },

    setSeguro(seguro: string) {
      this.facturaa.nombARS = seguro;
      this.showModalAseguradorasMethod();
      this.search = "";
      document.getElementById("fechAutor").focus();
    },

    buscar(term: string) {
      this.seguros2 = this.seguros;
      if (term !== "") {
        this.seguros2 = this.seguros.filter((seguro: string) => {
          return seguro.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      if (this.seguros2.length == 1) {
        if (confirm("¿" + this.seguros2[0] + "?")) {
          this.setSeguro(this.seguros2[0]);
          document.getElementById("autPor").focus();
        }
      }
    },

    showModalMethod7() {
      this.seguros2 = this.seguros;
      this.showModal7 = !this.showModal7;
      // document.getElementById("oficio").focus();
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    deleteService(it: any) {
      if (confirm("Está Seguro que desea Eliminar Esta Consulta?")) {
        this.facturaa.consultas.splice(it, 1);
      }
    },

    editService(index: number, consulta: any) {
      this.currentEdition = consulta;
      this.showModalMethod();
    },

    newFormatDate(dateValue: Date) {
      // let out = moment(dateValue).add(0, "h");
      // return moment(out).format("DD/MM/YYYY");
      moment.locale("es");
      return moment(dateValue).calendar();
      // .startOf("hour")
      // .fromNow();
    },

    async addActivity() {
      if (this.currentConsulta !== "") {
        this.facturaa.consultas.push({
          description: this.currentConsulta,
          date: new Date(),
        });
        this.currentConsulta = "";
      }
      document.getElementById("barcode").focus();
    },

    calcularEdad(date: any) {
      let years = 0;
      let edad = Math.floor(
        moment(new Date()).diff(moment(date, "YYYY-MM-DD"), "years", true)
      );
      if (edad > 120 || edad < 0) {
        years = 0;
      } else {
        years = edad;
      }
      this.facturaa.edad = years;
    },

    // async filterFacturaas() {
    //   this.toggleLoading();
    //   try {
    //     const res = await getPeg();
    //     this.facturaasPeg = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   try {
    //     const res = await getEmp();
    //     this.facturaasEmp = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   try {
    //     const res = await getMez();
    //     this.facturaasMez = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   this.toggleLoading();
    // },

    // async loadLigas() {
    //   // this.toggleLoading();
    //   try {
    //     const res = await getLigas();
    //     this.ligas = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   // this.toggleLoading();
    // },

    // async loadVitolas() {
    //   // this.toggleLoading();
    //   try {
    //     const res = await getVitolas();
    //     this.vitolas = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   // this.toggleLoading();
    // },

    async loadFacturaas() {
      this.toggleLoading();
      try {
        const res = await getFacturaas(this.criterio);
        this.seguros = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    fixTime() {
      if (this.facturaa.fechAutor) {
        this.facturaa.fechAutor = this.formatDateToFix(
          this.facturaa.fechAutor,
          false
        );
      }
      if (this.facturaa.ing) {
        this.facturaa.ing = this.formatDateToFix(this.facturaa.ing, false);
      }
      if (this.facturaa.egr) {
        this.facturaa.egr = this.formatDateToFix(this.facturaa.egr, false);
      }
      if (this.facturaa.fechaNacimiento) {
        this.facturaa.fechaNacimiento = this.formatDateToFix(
          this.facturaa.fechaNacimiento,
          false
        );
      }
    },

    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        let out = moment(dateValue).add(4, "hours");
        return moment(out).format("yyyy-MM-DD");
      }
    },

    async loadPatient(aflNSS: string) {
      this.toggleSpinner();
      try {
        const { data } = await GetByAflNSS(aflNSS);
        this.facturaa.cedu = data.cedula;
        this.facturaa.nombPac = data.nombre;
        this.facturaa.fechAutor = this.formatDateToFix(data.updatedAt, false);
        this.facturaa.nombARS = data.seguro;
        this.facturaa.sex = data.sexo;
        this.facturaa.telMed = data.telefono;
        this.facturaa.dx = data.diagnosticoPresuntivo;
        this.facturaa.fechaNacimiento = this.formatDateToFix(
          data.fechaNacimiento,
          false
        );
        this.facturaa.edad = data.edad;
        this.facturaa.dir = data.direccion;
        document.getElementById("aut").focus();
        // this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleSpinner();
    },

    async loadFacturaa(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getFacturaa(id);
        this.facturaa = data;
        // this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },

    async handleUpdate() {
      this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.facturaa.userMod = this.$store.state.user.usuario;
          await updateFacturaa(this.$route.params.id, this.facturaa);
          // this.addMessage();
          this.$router.push("/facturaas");
        }
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
      // this.toggleAlert();
    },

    async handleDelete() {
      if (confirm(this.mensageConfirm)) {
        try {
          if (typeof this.$route.params.id === "string") {
            await deleteFacturaa(this.$route.params.id);
            // this.addMessage();
            this.$router.push("/facturaas");
          }
        } catch (error) {
          //console.error(error);
        }
      }
    },

    showDeleteMethod() {
      if (this.$store.state.user.type == "Power User") {
        this.showDelete = true;
      }
    },

    toggleShowDatosPadre() {
      this.showDatosPadre = !this.showDatosPadre;
    },
    toggleShowDatosMadre() {
      this.showDatosMadre = !this.showDatosMadre;
    },
    toggleShowDatosTutor() {
      this.showDatosTutor = !this.showDatosTutor;
    },

    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    isError(message: string) {
      if (message == this.mensageExito) {
        return "success";
      } else {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcFacturaa() {
      (this.facturaa.retension = this.facturaa.bruto * 0.1),
        (this.facturaa.neto = this.facturaa.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(4, "hours");
      return moment(out).format("MM");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fillFields() {
      // this.facturaa.instit = "Instituto Avanzado de Artroscopía y Artroplastía";
      this.facturaa.nombPres = "Dr. Carlos Morales Ángeles";
      this.facturaa.tipServ = "Cirugía";
      // this.facturaa.descServ = "Consulta";
      this.facturaa.tipo = "med";
      this.facturaa.facturaa = 0;
      // this.facturaa.montRec = 500;

      // this.facturaa.fechaNac = new Date();
      // this.facturaa.fechaCont = new Date();
    },

    async loadOneFacturaa() {
      try {
        const res = await getOneFacturaa();
        this.oneFacturaa = res.data;
        this.one = this.oneFacturaa[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        if (this.nextNo == null) {
          this.nextNo = 0;
        }
        this.facturaa.no = this.nextNo;
        this.facturaa.principal = this.nextNo;
        this.facturaa.principal = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveFacturaaa() {
      await this.loadOneFacturaa();
      try {
        const res = await createFacturaaa(this.facturaa);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveFacturaa() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneFacturaa();
          this.oneFacturaa = res.data;
          this.one = this.oneFacturaa[0];
          this.nextNo = this.one.no + 1;
          this.facturaa.no = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.facturaa.userReg = this.$store.state.user.usuario;
        this.facturaa.aut = this.facturaa.aut.toUpperCase().trim();
        const res = await createFacturaa(this.facturaa).then(
          (res) => {
            this.error = this.respuesta = res.data.title;
            // this.$router.push("/");
            this.res = res;
            this.respuesta = res.data;
            // // this.addMessage();
          },
          (err) => {
            // console.log(err.response);
            this.error = err.response.data.error;
            this.showMessageMethod();
            document.getElementById("btnAcept").focus();
          }
        );
        // this.$router.push("/facturaas/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      if (this.error !== this.mensageError) {
        await this.cleanFields();
      }
      await this.fillFields();
      await this.fixTime();
      document.getElementById(this.campoFocus).focus();
      this.toggleAlert();
    },

    async deleteAllFacturaas() {
      try {
        const res = await eliminateFacturaas(this.facturaa);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    cleanFields() {
      this.facturaa.aflNSS = "";
      this.facturaa.cedu = "";
      this.facturaa.nombPac = "";
      this.facturaa.sex = "";
      this.facturaa.telMed = "";
      this.facturaa.fechaNacimiento = "";
      this.facturaa.edad = "";
      this.facturaa.dir = "";
      this.facturaa.aut = "";
      this.facturaa.dx = "";
      this.facturaa.nombARS = "";
      this.facturaa.tipServ = "";
      this.facturaa.montRec = "";
      this.facturaa.fechAutor = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    toggleSpinner() {
      this.showSpinner = !this.showSpinner;
    },

    focus() {
      document.getElementById(this.campoFocus).focus();
    },
    focus2() {
      document.getElementById(this.campoFocus2).focus();
    },

    isMed() {
      if (this.facturaa.tipo == "cent") {
        this.facturaa.nombPres = "Clínica Cruz Jiminián";
        return false;
      } else {
        return true;
      }
    },
  },
});
