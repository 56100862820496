
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Vitola } from "@/interfaces/Vitola";
import {
  createVitola,
  eliminateVitolas,
  createVitolaa,
  getOneVitola,
  deleteVitola,
  getVitola,
  updateVitola,
} from "@/services/expedientesclinicos/Vitola";
import { createMensaje } from "@/services/expedientesclinicos/ChatService";
import numeral from "numeral";
import moment from "moment";
import Pusher from "pusher-js";

export default defineComponent({
  name: "vitolas-form",
  components: {
    Navbar,
  },
  data() {
    return {
      showDatosPadre: false,
      showDatosMadre: false,
      showDatosTutor: false,
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      vitola: {} as Vitola,
      oneVitola: {} as Vitola,
      one: {},
      nextNo: Number,
      medicoSelected: [],
      clienteSelected: [],
      descripcionCliente: "",

      showInComentary: "",
      showModal: false,
      showDelete: false,
      currentActivity: "",
      estado: {} as object,
    };
  },

  mounted() {
    // this.vitola.no = 1;
    // this.defFields();

    this.showDeleteMethod();
    if (typeof this.$route.params.id === "string") {
      this.loadVitola(this.$route.params.id);
    }
    this.pusherSubscribe();

    this.focus();
  },

  methods: {
    async loadVitola(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getVitola(id);
        this.vitola = data;
        // this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },

    async handleUpdate() {
      // this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.vitola.userMod = this.$store.state.user.usuario;
          this.vitola.descripcion = this.vitola.tipo + " " + this.vitola.tamano;
          await updateVitola(this.$route.params.id, this.vitola);
          this.addMessage();
          this.$router.push("/vitolas");
        }
      } catch (error) {
        //console.error(error);
      }
      // this.toggleLoading();
      // this.toggleAlert();
    },

    async handleDelete() {
      if (confirm("¿Está Seguro que desea Eliminar Esta Vitola?")) {
        try {
          if (typeof this.$route.params.id === "string") {
            await deleteVitola(this.$route.params.id);
            this.addMessage();
            this.$router.push("/vitolas");
          }
        } catch (error) {
          //console.error(error);
        }
      }
    },

    pusherSubscribe() {
      // Start pusher subscribe
      var pusher = new Pusher("d7b50b87118775ed0b11", {
        cluster: "us2",
      });

      var channel = pusher.subscribe("my-channel");
      channel.bind("my-event", (data: any) => {
        if (typeof this.$route.params.id === "string") {
          this.loadVitola2(this.$route.params.id);
        }
        // this.player.src = this.song.src;
        // this.player.play();
      });
      // End pusher subscribe
    },

    showDeleteMethod() {
      if (this.$store.state.user.type == "Power User") {
        this.showDelete = true;
      }
    },

    toggleShowDatosPadre() {
      this.showDatosPadre = !this.showDatosPadre;
    },
    toggleShowDatosMadre() {
      this.showDatosMadre = !this.showDatosMadre;
    },
    toggleShowDatosTutor() {
      this.showDatosTutor = !this.showDatosTutor;
    },

    calcularEdad(date: any) {
      let years = 0;
      let edad = Math.floor(
        moment(new Date()).diff(moment(date, "YYYY-MM-DD"), "years", true)
      );
      if (edad > 120 || edad < 0) {
        years = 0;
      } else {
        years = edad;
      }
      this.vitola.edaddelvitola = years;
    },

    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DTHH:mm");
      } else {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-D");
      }
    },

    fixTime() {
      this.vitola.fecha_ingreso = this.formatDateToFix(
        this.vitola.fecha_ingreso,
        true
      );
    },

    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    // async getVitola() {
    //   this.toggleLoading();
    //   this.documento.idfact = this.vitola.idfact;
    //   if (this.documento) {
    //     try {
    //       const res = await getVitola(this.documento);
    //       // const res = await getVitolas();
    //       // this.vitola = res.data;
    //       // Asignar Campos Seleccionandolos
    //       this.vitola.idfact = res.data.idfact;
    //       this.vitola.id_ars = res.data.id_ars;
    //       this.vitola.nom = res.data.nom;
    //       this.vitola.nro_autorizacion_salida =
    //         res.data.nro_autorizacion_salida;
    //       this.vitola.fecha_ingreso = res.data.fecha_ingreso;
    //       this.vitola.numero_afiliado = res.data.numero_afiliado;
    //       this.vitola.rnc = res.data.rnc;
    //       this.vitola.tipo_vitola = res.data.tipo_vitola;
    //       this.vitola.cobertura = res.data.cobertura;
    //       this.vitola.total_servicio = res.data.total_servicio;

    //       this.vitola.status = this.$store.state.user.defaultStatus;
    //       this.vitola.actividad = [];
    //       this.vitola.actividad.push({
    //         description: this.$store.state.user.defaultStatus,
    //         date: new Date(),
    //         user: this.$store.state.user.usuario,
    //         detalles: "",
    //       });
    //       this.fixTime();
    //     } catch (error) {
    //       // console.error(error);
    //     }
    //   }
    //   await this.toggleLoading();
    //   if (this.vitola.cobertura == 0) {
    //     // this.saveVitola();
    //     // alert("Vitola Encontrada");
    //     alert("Vitola no Encontrada");
    //     this.focus();
    //   }
    // },

    isError(message: string) {
      if (message == "Vitola Registrada Exitosamente") {
        return "success";
      } else if (message == "Ya Existe una Vitola Registrada con este Id") {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcVitola() {
      (this.vitola.retension = this.vitola.bruto * 0.1),
        (this.vitola.neto = this.vitola.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    // defFields() {
    // this.vitola.status = this.$store.state.user.defaultStatus;
    // this.actividad = "4 - Recibido por Reclamaciones Médicas";
    // this.vitola.actividad.push(this.actividad);
    // },

    async loadOneVitola() {
      try {
        const res = await getOneVitola();
        this.oneVitola = res.data;
        this.one = this.oneVitola[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        if (this.nextNo == null) {
          this.nextNo = 0;
        }
        this.vitola.no = this.nextNo;
        this.vitola.principal = this.nextNo;
        this.vitola.principal = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveVitolaa() {
      await this.loadOneVitola();
      try {
        const res = await createVitolaa(this.servicio);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveVitola() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneVitola();
          this.oneVitola = res.data;
          this.one = this.oneVitola[0];
          this.nextNo = this.one.no + 1;
          this.vitola.no = this.nextNo;
          this.vitola.principal = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.vitola.status = this.$store.state.user.defaultStatus;
        this.vitola.userReg = this.$store.state.user.usuario;
        const res = await createVitola(this.vitola).then(
          (res) => {
            this.error = this.respuesta = res.data.title;
            // this.$router.push("/");
            this.res = res;
            this.respuesta = res.data;
            this.addMessage();
          },
          (err) => {
            // console.log(err.response);
            this.error = err.response.data.error;
          }
        );
        // this.$router.push("/vitolas/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      await this.definingFields();
      // await this.defFields();
      document.getElementById("tamano").focus();
      this.toggleAlert();
    },

    async deleteAllVitolas() {
      try {
        const res = await eliminateVitolas(this.vitola);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    definingFields() {
      this.vitola.tamano = "";
      this.vitola.tipo = "";
      this.vitola.pago = "";
      this.vitola.costo = "";
      this.vitola.descripcion = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById("tamano").focus();
    },
  },
});
