
import numeral from "numeral";
import moment from "moment";
import { updateOne, updateReporte } from "@/services/expedientesclinicos/ReporteService";
import { Reporte } from "@/interfaces/Reporte";
import { createMensaje } from "@/services/expedientesclinicos/ChatService";

export default {
  props: ["reportes"],
  data() {
    return {
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      nuevoReporte: {} as any,
      nuevoReporte2: {} as any,
      data: false,
      totales: {
        reportes: 0,
        bruto: 0,
        retension: 0,
        neto: 0,
      },
    };
  },
  methods: {
    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    async priorizar(
      arr: any,
      fromIndex: number,
      toIndex: number,
      descripcion: string
    ) {
      if (fromIndex !== 0) {
        // Array Local
        let element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);

        // Base de Datos
        this.nuevoReporte = this.reportes[fromIndex - 1];
        this.nuevoReporte2 = this.reportes[fromIndex];
        this.nuevoReporte.no = this.reportes[fromIndex].no;
        this.nuevoReporte2.no = this.reportes[fromIndex].no + 1;

        await updateReporte(
          this.reportes[fromIndex - 1]._id,
          this.nuevoReporte
        );

        await updateReporte(this.reportes[fromIndex]._id, this.nuevoReporte2);

        this.addMessage();
      } else {
        alert(descripcion + ", Ya está en su Máxima Prioridad.");
      }
    },

    async marcarListo(reporte: Reporte) {
      alert("Reporte Lista.");
      try {
        const res = await updateOne(reporte);
      } catch (error) {
        // console.error(error);
      }
    },

    toColor2(status: string) {
      if (status == "Reportado") {
        return "badge rounded-pill bg-secondary";
      } else if (status == "Recibido") {
        return "badge rounded-pill bg-dark";
      } else if (status == "Evaluado") {
        return "badge rounded-pill bg-danger";
      } else if (status == "Priorizado") {
        return "badge rounded-pill bg-danger";
      } else if (status == "Inicializado") {
        return "badge rounded-pill bg-warning";
      } else if (status == "Detenido") {
        return "badge rounded-pill bg-warning";
      } else if (status == "Concluído") {
        return "badge rounded-pill bg-success";
      } else if (status == "Entregado") {
        return "badge rounded-pill bg-success";
      } else if (status == "Seguido") {
        return "badge rounded-pill bg-info";
      } else if (status == "Validado") {
        return "badge rounded-pill bg-primary";
      }
    },

    toColor(type: string) {
      if (type == "Soporte") {
        return "card text-white bg-danger mb-3";
      } else if (type == "Asistencia") {
        return "card text-white bg-success mb-3";
      } else if (type == "Servicio") {
        return "card text-white bg-warning mb-3";
      }
    },
    valorTotal() {
      this.totales.reportes = this.reportes.length;
      this.totales.prefactura = this.reportes.reduce(
        (accum: any, item: any) => accum + item.prefactura,
        0
      );
    },

    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("0,0");
    },

    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },

    newFormatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      // return moment(out).format("DD/MM/YYYY");
      return moment(out).startOf('hour').fromNow();
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("DD/MM/YYYY");
    },
  },

  updated() {
    this.valorTotal();
    // this.data = !this.data;
  },
};
