
import { registerUsuario } from "@/services/expedientesclinicos/UsuarioService";
import { Usuario } from "@/interfaces/Usuario";
export default {
  name: "LoginView",
  components: {},

  data() {
    return {
      cargando: false,
      error: "",
      usuario: {} as Usuario,
      respuesta: {},
      resp: {},
    };
  },

  methods: {
    arreglar() {
      this.usuario.email = this.usuario.email.toLowerCase().trim();
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    async signup() {
      if (this.usuario.password.length >= 8) {
        this.toggleLoading();
        this.arreglar();
        const resp = await registerUsuario(this.usuario).then(
          (res) => {
            this.error = "";
            this.$router.push("/");
            this.resp = resp;
            this.respuesta = res;
          },
          (err) => {
            // console.log(err.response);
            this.error = err.response.data.error;
          }
        );
        this.toggleLoading();
      } else {
        alert("La contraseña debe tener 8 caracteres mínimo");
      }
    },

    // login() {
    //   this.$store.dispatch("loginAction");
    //   this.$store.state.user.type = this.role;
    //   this.$router.push("/dashboard");
    // },
  },
};
