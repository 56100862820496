
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Empleado } from "@/interfaces/Empleado";
import { Funcion } from "@/interfaces/Funcion";
import {
  createEmpleado,
  eliminateEmpleados,
  createEmpleadoa,
  getOneEmpleado,
  deleteEmpleado,
  getEmpleado,
  updateEmpleado,
} from "@/services/expedientesclinicos/Empleado";
import { getFuncions } from "@/services/expedientesclinicos/Funcion";
import { createMensaje } from "@/services/expedientesclinicos/ChatService";
import numeral from "numeral";
import moment from "moment";
// import Pusher from "pusher-js";

export default defineComponent({
  name: "empleados-form",
  components: {
    Navbar,
  },
  data() {
    return {
      encabezado: "",
      modoForm: "",
      funciones: [] as Funcion[],
      showDatosPadre: false,
      showDatosMadre: false,
      showDatosTutor: false,
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      empleado: {} as Empleado,
      oneEmpleado: {} as Empleado,
      one: {},
      nextNo: Number,
      medicoSelected: [],
      clienteSelected: [],
      descripcionCliente: "",

      showInComentary: "",
      showModal: false,
      showDelete: false,
      currentActivity: "",
      estado: {} as object,
    };
  },

  async mounted() {
    if (this.$route.fullPath == "/empleados/new") {
      this.modoForm = "add";
      this.encabezado = "Nuevo Empleado";
    } else {
      this.modoForm = "show";
      this.encabezado = "Detalles de Empleado";
    }

    if (this.modoForm == "add") {
      this.empleado.no = 1;
      this.fillFields();
    } else {
      this.showDeleteMethod();
      if (typeof this.$route.params.id === "string") {
        await this.loadEmpleado(this.$route.params.id);
      }
    }

    // this.pusherSubscribe();

    this.focus();
    this.loadFunciones();
  },

  methods: {
    async loadFunciones() {
      this.toggleLoading();
      try {
        const res = await getFuncions();
        this.funciones = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadEmpleado(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getEmpleado(id);
        this.empleado = data;
        this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },

    async handleUpdate() {
      // this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.empleado.userMod = this.$store.state.user.usuario;
          this.empleado.nombre =
            this.empleado.primerNombre +
            " " +
            this.empleado.segundoNombre +
            " " +
            this.empleado.primerApellido +
            " " +
            this.empleado.segundoApellido;
          await updateEmpleado(this.$route.params.id, this.empleado);
          this.addMessage();
          this.$router.push("/empleados");
        }
      } catch (error) {
        //console.error(error);
      }
      // this.toggleLoading();
      // this.toggleAlert();
    },

    async handleDelete() {
      if (confirm("¿Está Seguro que desea Eliminar Este Empleado?")) {
        try {
          if (typeof this.$route.params.id === "string") {
            await deleteEmpleado(this.$route.params.id);
            this.addMessage();
            this.$router.push("/empleados");
          }
        } catch (error) {
          //console.error(error);
        }
      }
    },

    // pusherSubscribe() {
    //   // Start pusher subscribe
    //   var pusher = new Pusher("d7b50b87118775ed0b11", {
    //     cluster: "us2",
    //   });

    //   var channel = pusher.subscribe("my-channel");
    //   channel.bind("my-event", (data: any) => {
    //     if (typeof this.$route.params.id === "string") {
    //       this.loadEmpleado2(this.$route.params.id);
    //     }
    //     // this.player.src = this.song.src;
    //     // this.player.play();
    //   });
    //   // End pusher subscribe
    // },

    showDeleteMethod() {
      if (this.$store.state.user.type == "Power User") {
        this.showDelete = true;
      }
    },

    toggleShowDatosPadre() {
      this.showDatosPadre = !this.showDatosPadre;
    },
    toggleShowDatosMadre() {
      this.showDatosMadre = !this.showDatosMadre;
    },
    toggleShowDatosTutor() {
      this.showDatosTutor = !this.showDatosTutor;
    },

    calcularEdad(date: any) {
      let years = 0;
      let edad = Math.floor(
        moment(new Date()).diff(moment(date, "YYYY-MM-DD"), "years", true)
      );
      if (edad > 120 || edad < 0) {
        years = 0;
      } else {
        years = edad;
      }
      this.empleado.edad = years;
    },

    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DTHH:mm");
      } else {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-D");
      }
    },

    fixTime() {
      this.empleado.fechaNacimiento = this.formatDateToFix(
        this.empleado.fechaNacimiento,
        false
      );
      this.empleado.fechaContratacion = this.formatDateToFix(
        this.empleado.fechaContratacion,
        false
      );
    },

    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    isError(message: string) {
      if (message == "Empleado Registrado Exitosamente") {
        return "success";
      } else {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcEmpleado() {
      (this.empleado.retension = this.empleado.bruto * 0.1),
        (this.empleado.neto = this.empleado.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fillFields() {
      this.empleado.modalidad = "Jornalero";
      this.empleado.metodoPago = "Efectivo";
    },

    async loadOneEmpleado() {
      try {
        const res = await getOneEmpleado();
        this.oneEmpleado = res.data;
        this.one = this.oneEmpleado[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        if (this.nextNo == null) {
          this.nextNo = 0;
        }
        this.empleado.no = this.nextNo;
        this.empleado.principal = this.nextNo;
        this.empleado.principal = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveEmpleadoa() {
      await this.loadOneEmpleado();
      try {
        const res = await createEmpleadoa(this.servicio);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveEmpleado() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneEmpleado();
          this.oneEmpleado = res.data;
          this.one = this.oneEmpleado[0];
          this.nextNo = this.one.no + 1;
          this.empleado.no = this.nextNo;
          this.empleado.principal = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.empleado.status = this.$store.state.user.defaultStatus;
        this.empleado.userReg = this.$store.state.user.usuario;
        this.empleado.nombre =
          this.empleado.primerNombre +
          " " +
          this.empleado.segundoNombre +
          " " +
          this.empleado.primerApellido +
          " " +
          this.empleado.segundoApellido;
        const res = await createEmpleado(this.empleado).then(
          (res) => {
            this.error = this.respuesta = res.data.title;
            // this.$router.push("/");
            this.res = res;
            this.respuesta = res.data;
            this.addMessage();
          },
          (err) => {
            // console.log(err.response);
            this.error = err.response.data.error;
          }
        );
        // this.$router.push("/empleados/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      if (
        this.error !==
        "Ya Existe un Empleado Registrado con esta Cédula o con Este Número de Ponchador"
      ) {
        await this.cleanFields();
      }
      await this.fillFields();
      document.getElementById("cedula").focus();
      this.toggleAlert();
    },

    async deleteAllEmpleados() {
      try {
        const res = await eliminateEmpleados(this.empleado);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    cleanFields() {
      this.empleado.cedula = "";
      this.empleado.nombre = "";
      this.empleado.funcion = "";
      this.empleado.modalidad = "";
      this.empleado.sueldo = "";
      this.empleado.metodoPago = "";
      this.empleado.cuenta = "";
      this.empleado.codigoPonchador = "";
      this.empleado.primerNombre = "";
      this.empleado.segundoNombre = "";
      this.empleado.primerApellido = "";
      this.empleado.segundoApellido = "";
      this.empleado.sexo = "";
      this.empleado.fechaNacimiento = "";
      this.empleado.edad = "";
      this.empleado.direccion = "";
      this.empleado.telResidencial = "";
      this.empleado.telCelular = "";
      this.empleado.noColaborador = "";
      this.empleado.departamento = "";
      this.empleado.fechaContratacion = "";
      this.empleado.cargo = "";
      this.empleado.estudiosRealizados = "";
      this.empleado.nombreConyuge = "";
      this.empleado.cedulaConyuge = "";
      this.empleado.seguroSocial = "";
      this.empleado.estadoCivil = "";
      this.empleado.tipoSangre = "";
      this.empleado.alergias = "";
      this.empleado.enEmergenciasLlamar = "";
      this.empleado.parentezcoLlamar = "";
      this.empleado.telefonoLlamar = "";
      this.empleado.hijos = "";
      this.empleado.recursosHumanos = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById("cedula").focus();
    },
  },
});
