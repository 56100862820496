
import Filters from "@/components/Liga/FiltersComponent.vue";
import ListadoComponent from "@/components/Liga/ListComponent.vue";
import Navbar from "@/components/Navbar.vue";
import { Liga } from "@/interfaces/Liga";
import { getLigas } from "@/services/expedientesclinicos/Liga";
import Pusher from "pusher-js";

export default {
  name: "App",

  components: {
    Filters,
    ListadoComponent,
    Navbar,
  },

  data() {
    return {
      song: {
        title: "Notification",
        src: require("@/assets/sounds/notification.mp3"),
      },
      player: new Audio(),
      showModal: false,
      cargando: false,
      ligas: [] as Liga[],
      str: "",
      type: "",
    };
  },

  methods: {
    pusherSubscribe() {
      // Start pusher subscribe
      var pusher = new Pusher("d7b50b87118775ed0b11", {
        cluster: "us2",
      });

      var channel = pusher.subscribe("my-channel");
      channel.bind("my-event", (data: any) => {
        this.loadLigas2();
        // this.player.src = this.song.src;
        // this.player.play();
      });
      // End pusher subscribe
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },
    async filterLigas(catName: string) {
      try {
        const res = await getLigas();
        this.ligas = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (catName !== "Todos") {
        this.ligas = this.ligas.filter((liga: Liga) => {
          return liga.descripcion === catName;
        });
      }
    },

    async search(term: string) {
      this.toggleLoading();
      try {
        const res = await getLigas();
        this.ligas = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (term !== "Todos") {
        this.ligas = this.ligas.filter((liga: Liga) => {
          return liga.descripcion.toLowerCase().includes(term.toLowerCase());
        });
      }
      this.toggleLoading();
    },

    async loadLigas() {
      this.toggleLoading();
      try {
        const res = await getLigas();
        this.ligas = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadLigas2() {
      this.toggleLoading();
      try {
        const res = await getLigas();
        this.ligas = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },
  },

  mounted() {
    this.loadLigas();
    this.pusherSubscribe();
  },
};
