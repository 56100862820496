
import { defineComponent } from "vue";
import { Receta } from "@/interfaces/Receta";
import {
  deleteReceta,
  getReceta,
  updateReceta,
} from "@/services/expedientesclinicos/Receta";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "receta-list",
  data() {
    return {
      rxs: [
        { titu: "Analgésico", desc: "1 diario x 8 días" },
        { titu: "Antinflamatorio", desc: "2 al día cada 8 hrs x 12 días" },
      ],
      cargando: false,
      receta: {} as Receta,
    };
  },
  methods: {
    auto_grow(element: { style: { height: string }; scrollHeight: string }) {
      element.style.height = "5px";
      element.style.height = element.scrollHeight + "px";
    },
    toPrint() {
      window.print();
    },
    toggleLoading() {
      this.cargando = !this.cargando;
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("D/MM/yyyy HH:mm");
    },

    formatDate2(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("D/MM/yyyy");
    },

    async loadReceta(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getReceta(id);
        this.receta = data;
      } catch (error) {
        //console.error(error);
      }
      await this.toggleLoading();
      this.toPrint();

      if (this.$store.state.ids.length == 0) {
        this.$router.push(`/recetas/${this.receta._id}`);
      } else {
        this.$router.push(`/recetaslot/new`);
      }

      this.$router.push(`/recetas/${this.receta._id}`);
    },

    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateReceta(this.$route.params.id, this.receta);
          this.$router.push("/recetas");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteReceta(this.$route.params.id);
          this.$router.push("/recetas");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadReceta(this.$route.params.id);
    }
  },

  // beforeUnmount() {
  //   this.toPrint();
  // }
});
