
import Navbar from "@/components/Navbar.vue";
import { createMensaje } from "@/services/expedientesclinicos/ChatService";
import { defineComponent } from "vue";
import { getUsuarios } from "@/services/expedientesclinicos/UsuarioService";
import { Reporte } from "@/interfaces/Reporte";
import { Usuario } from "@/interfaces/Usuario";
import {
  getReportes,
  deleteReporte,
  getReporte,
  updateReporte,
} from "@/services/expedientesclinicos/ReporteService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "reporte-list",
  components: {
    Navbar,
  },
  data() {
    return {
      usuarios: [] as Usuario[],
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      reportes: [] as Reporte[],
      showAlert: false,
      cargando: false,
      clienteSelected: [],
      medicoSelected: [],
      reporte: {} as Reporte,
      estado: {} as object,
    };
  },
  methods: {
    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    async loadReportes() {
      try {
        const res = await getReportes();
        this.reportes = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    async facturar() {
      this.reporte.cantPrefactura = 0;
      this.reporte.cantFactura = 1;
      this.reporte.credito = 0;
      this.reporte.debito = 0;
      this.reporte.subTipo = "Factura";
      this.reporte.factura = this.reporte.prefactura;
      this.reporte.prefactura = 0;
      await this.selectCliente(this.reporte.descCliente);
      this.reporte.literal = this.clienteSelected[0].tipoComprobante.substring(
        0,
        3
      );
      await this.selectMedico(this.reporte.medico);
      if (this.reporte.literal == "B15") {
        this.reporte.ncf = this.medicoSelected[0].compGuberAsig;
        this.reporte.vencimiento = this.medicoSelected[0].fechGuberAsig;
        this.reporte.fechaNCF = new Date();
      } else {
        this.reporte.ncf = this.medicoSelected[0].compNoGuberAsig;
        this.reporte.vencimiento = this.medicoSelected[0].fechNoGuberAsig;
        this.reporte.fechaNCF = new Date();
      }
      this.handleUpdate();
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },

    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fixTime() {
      this.reporte.fechaProceso = this.formatDate(this.reporte.fechaProceso);
      this.reporte.fechaEfectivo = this.formatDate(this.reporte.fechaEfectivo);
      this.reporte.fechaVence = this.formatDate(this.reporte.fechaVence);
    },

    async loadReporte(id: string) {
      this.toggleLoading();
      this.loadReportes();
      try {
        const { data } = await getReporte(id);
        this.reporte = data;
        this.estado = this.reporte.estado;
        this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },
    async handleUpdate() {
      this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.reporte.userMod = this.$store.state.user.usuario;
          await updateReporte(this.$route.params.id, this.reporte);
          if (this.reporte.literal == "B15") {
            this.medicoSelected[0].compGuberAsig += 1;
          } else {
            this.medicoSelected[0].compNoGuberAsig += 1;
          }
        }
      } catch (error) {
        //console.error(error);
      }
      await this.addMessage();
      this.toggleLoading();
      this.$router.push("/reportes");
      // this.toggleAlert();
    },
    async handleDelete() {
      this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          await deleteReporte(this.$route.params.id);
          this.$router.push("/reportes");
        }
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
      await this.addMessage();
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    async loadUsuarios() {
      // this.toggleLoading();
      try {
        const res = await getUsuarios();
        this.usuarios = res.data;
      } catch (error) {
        // console.error(error);
      }
      // this.toggleLoading();
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadReporte(this.$route.params.id);
    }
    this.loadUsuarios();
  },
});
