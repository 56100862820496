
import numeral from "numeral";
import moment from "moment";
import { updateOne } from "@/services/expedientesclinicos/MejoraService";
import { Mejora } from "@/interfaces/Mejora";

export default {
  props: ["mejoras"],
  data() {
    return {
      data: false,
      totales: {
        mejoras: 0,
        bruto: 0,
        retension: 0,
        neto: 0,
      },
    };
  },
  methods: {
    async marcarListo(mejora: Mejora) {
      alert("Mejora Lista.");
      try {
        const res = await updateOne(mejora);
      } catch (error) {
        // console.error(error);
      }
    },

    toColor(type: string) {
      if (type == "Error") {
        return "table-danger";
      } else if (type == "Función") {
        return "table-success";
      } else if (type == "Mejora") {
        return "table-warning";
      }
    },
    valorTotal() {
      this.totales.mejoras = this.mejoras.length;
      this.totales.prefactura = this.mejoras.reduce(
        (accum: any, item: any) => accum + item.prefactura,
        0
      );
    },

    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("0,0");
    },

    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("DD/MM/YYYY");
    },
  },

  updated() {
    this.valorTotal();
    // this.data = !this.data;
  },
};
