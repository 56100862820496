
import Navbar from "@/components/Navbar.vue";
import { Licencia } from "@/interfaces/Licencia";
import { getLicenciasByExp } from "@/services/expedientesclinicos/Licencia";
import { getRecetasByExp } from "@/services/expedientesclinicos/Receta";
import { defineComponent } from "vue";
import { Expediente } from "@/interfaces/Expediente";
import { Funcion } from "@/interfaces/Funcion";
import { Vitola } from "@/interfaces/Vitola";
import { Empleado } from "@/interfaces/Empleado";
import {
  getPeg,
  getMez,
  getEmp,
} from "@/services/expedientesclinicos/Empleado";
import { getLigas } from "@/services/expedientesclinicos/Liga";
import {
  createExpediente,
  eliminateExpedientes,
  createExpedientea,
  getOneExpediente,
  deleteExpediente,
  getExpediente,
  updateExpediente,
} from "@/services/expedientesclinicos/Expediente";
import { getVitolas } from "@/services/expedientesclinicos/Vitola";
import { getFuncions } from "@/services/expedientesclinicos/Funcion";
import { createMensaje } from "@/services/expedientesclinicos/ChatService";
import numeral from "numeral";
import moment from "moment";
// import Pusher from "pusher-js";

export default defineComponent({
  name: "expedientes-form",
  components: {
    Navbar,
  },
  data() {
    return {
      licencias: [] as Licencia[],
      search: "",
      seguros2: [],
      seguros: [
        "NINGUNO",
        "ARS CMD",
        "MAPFRE SALUD ARS, S.A.",
        "FUTURO",
        "META SALUD",
        "YUNEN",
        "RESERVAS",
        "MONUMENTAL",
        "ASEMAP",
        "ARS APS S A",
        "SIMAG",
        "RENACER",
        "GRUPO MEDICO ASOCIADO",
        "PRIMERA ARS DE HUMANO",
        "UNIVERSAL",
        "HUMANO SEGUROS",
        "SENASA CONTRIBUTIVO",
        "SENASA SUBSIDIADO",
        "SEMMA",
        "IDOPPRIL",
        "ARS UASD",
        "OTRA",
      ],
      currentEdition: {} as any,
      vitolas: [] as Vitola[],
      empleados: [] as Empleado[],
      empleadosEmp: [] as Empleado[],
      empleadosPeg: [] as Empleado[],
      empleadosMez: [] as Empleado[],
      campoFocus: "refxeridoPor",
      mensageError: "Error",
      mensageExito: "Expediente Registrado Exitosamente",
      mensageConfirm: "¿Está Seguro que desea Eliminar Este Expediente?",
      encabezado: "",
      modoForm: "",
      funciones: [] as Funcion[],
      showDatosPadre: false,
      showDatosMadre: false,
      showDatosTutor: false,
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      expediente: {} as Expediente,
      oneExpediente: {} as Expediente,
      one: {},
      nextNo: Number,
      medicoSelected: [],
      clienteSelected: [],
      descripcionCliente: "",

      showInComentary: "",
      showModal: false,
      showModal7: false,
      showDelete: false,
      currentConsulta: "",
      estado: {} as object,
    };
  },

  async mounted() {
    if (this.$route.fullPath == "/expedientes/new") {
      this.modoForm = "add";
      this.encabezado = "Nuevo Expediente";
    } else {
      this.modoForm = "show";
      this.encabezado = "Detalles de Expediente";
    }

    if (this.modoForm == "add") {
      this.expediente.no = 1;
      this.expediente.consultas = [];
      this.fillFields();
      this.fixTime();
    } else {
      this.showDeleteMethod();
      if (typeof this.$route.params.id === "string") {
        await this.loadExpediente(this.$route.params.id);
        await this.calcularEdad(this.expediente.fechaNacimiento);
        await this.handleUpdateEdad();
        await this.loadLicenciasByExp({ nombre: this.expediente.nombre });
        await this.loadRecetasByExp({ nombre: this.expediente.nombre });
      }
      this.fixTime();
    }

    this.focus();
    // this.loadEmpleados();
    // this.loadLigas();
    // this.loadVitolas();
    // this.filterEmpleados();
  },

  methods: {
    setSeguro(seguro: string) {
      this.expediente.seguro = seguro;
      this.showModalMethod7();
      this.search = "";
      document.getElementById("oficio").focus();
    },

    buscar(term: string) {
      this.seguros2 = this.seguros;
      if (term !== "") {
        this.seguros2 = this.seguros.filter((seguro: string) => {
          return seguro.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      if (this.seguros2.length == 1) {
        if (confirm("¿" + this.seguros2[0] + "?")) {
          this.setSeguro(this.seguros2[0]);
          document.getElementById("oficio").focus();
        }
      }
    },

    showModalMethod7() {
      this.seguros2 = this.seguros;
      this.showModal7 = !this.showModal7;
      // document.getElementById("oficio").focus();
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    deleteService(it: any) {
      if (confirm("Está Seguro que desea Eliminar Esta Consulta?")) {
        this.expediente.consultas.splice(it, 1);
      }
    },

    editService(index: number, consulta: any) {
      this.currentEdition = consulta;
      this.showModalMethod();
    },

    newFormatDate(dateValue: Date) {
      // let out = moment(dateValue).add(0, "h");
      // return moment(out).format("DD/MM/YYYY");
      moment.locale("es");
      return moment(dateValue).calendar();
      // .startOf("hour")
      // .fromNow();
    },

    async addActivity() {
      if (this.currentConsulta !== "") {
        this.expediente.consultas.push({
          description: this.currentConsulta,
          date: new Date(),
        });
        this.currentConsulta = "";
      }
      document.getElementById("currentConsulta").focus();
    },

    calcularEdad(date: any) {
      let years = 0;
      let edad = Math.floor(
        moment(new Date()).diff(moment(date, "YYYY-MM-DD"), "years", true)
      );
      if (edad > 120 || edad < 0) {
        years = 0;
      } else {
        years = edad;
      }
      this.expediente.edad = years;
    },

    async filterEmpleados() {
      this.toggleLoading();
      try {
        const res = await getPeg();
        this.empleadosPeg = res.data;
      } catch (error) {
        // console.error(error);
      }

      try {
        const res = await getEmp();
        this.empleadosEmp = res.data;
      } catch (error) {
        // console.error(error);
      }

      try {
        const res = await getMez();
        this.empleadosMez = res.data;
      } catch (error) {
        // console.error(error);
      }

      this.toggleLoading();
    },

    async loadLigas() {
      // this.toggleLoading();
      try {
        const res = await getLigas();
        this.ligas = res.data;
      } catch (error) {
        // console.error(error);
      }
      // this.toggleLoading();
    },

    async loadVitolas() {
      // this.toggleLoading();
      try {
        const res = await getVitolas();
        this.vitolas = res.data;
      } catch (error) {
        // console.error(error);
      }
      // this.toggleLoading();
    },

    // async loadEmpleados() {
    //   this.toggleLoading();
    //   try {
    //     const res = await getEmpleados();
    //     this.empleados = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   this.toggleLoading();
    // },

    fixTime() {
      this.expediente.fecha = this.formatDateToFix(this.expediente.fecha, true);
      this.expediente.fechaActual = this.formatDateToFix(
        this.expediente.fechaActual,
        true
      );
      this.expediente.fechaNacimiento = this.formatDateToFix(
        this.expediente.fechaNacimiento,
        false
      );
    },

    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DD");
      }
    },

    async loadExpediente(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getExpediente(id);
        this.expediente = data;
        // this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },

    async loadLicenciasByExp(nombre: string) {
      this.toggleLoading();
      try {
        const { data } = await getLicenciasByExp(nombre);
        this.licencias = data;
        // this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },

    async loadRecetasByExp(nombre: string) {
      this.toggleLoading();
      try {
        const { data } = await getRecetasByExp(nombre);
        this.recetas = data;
        // this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },

    async handleUpdateEdad() {
      this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.expediente.userMod = this.$store.state.user.usuario;
          await updateExpediente(this.$route.params.id, this.expediente);
          // this.addMessage();
          // this.$router.push("/expedientes");
        }
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
      // this.toggleAlert();
    },

    async handleUpdate() {
      this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.expediente.userMod = this.$store.state.user.usuario;
          await updateExpediente(this.$route.params.id, this.expediente);
          this.addMessage();
          this.$router.push("/expedientes");
        }
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
      // this.toggleAlert();
    },

    async handleDelete() {
      if (confirm(this.mensageConfirm)) {
        try {
          if (typeof this.$route.params.id === "string") {
            await deleteExpediente(this.$route.params.id);
            this.addMessage();
            this.$router.push("/expedientes");
          }
        } catch (error) {
          //console.error(error);
        }
      }
    },

    showDeleteMethod() {
      if (this.$store.state.user.type == "Power User") {
        this.showDelete = true;
      }
    },

    toggleShowDatosPadre() {
      this.showDatosPadre = !this.showDatosPadre;
    },
    toggleShowDatosMadre() {
      this.showDatosMadre = !this.showDatosMadre;
    },
    toggleShowDatosTutor() {
      this.showDatosTutor = !this.showDatosTutor;
    },

    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    isError(message: string) {
      if (message == this.mensageExito) {
        return "success";
      } else {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcExpediente() {
      (this.expediente.retension = this.expediente.bruto * 0.1),
        (this.expediente.neto = this.expediente.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    formatDateLic(dateValue: Date) {
      let out = moment(dateValue).add(4, "h");
      return moment(out).format("D/MM/yyyy HH:mm");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fillFields() {
      this.expediente.fechaActual = new Date();
      this.expediente.fecha = new Date();
    },

    async loadOneExpediente() {
      try {
        const res = await getOneExpediente();
        this.oneExpediente = res.data;
        this.one = this.oneExpediente[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        if (this.nextNo == null) {
          this.nextNo = 0;
        }
        this.expediente.no = this.nextNo;
        this.expediente.principal = this.nextNo;
        this.expediente.principal = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveExpedientea() {
      await this.loadOneExpediente();
      try {
        const res = await createExpedientea(this.servicio);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveExpediente() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneExpediente();
          this.oneExpediente = res.data;
          this.one = this.oneExpediente[0];
          this.nextNo = this.one.no + 1;
          this.expediente.no = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.expediente.userReg = this.$store.state.user.usuario;
        const res = await createExpediente(this.expediente).then(
          (res) => {
            this.error = this.respuesta = res.data.title;
            // this.$router.push("/");
            this.res = res;
            this.respuesta = res.data;
            this.addMessage();
          },
          (err) => {
            // console.log(err.response);
            this.error = err.response.data.error;
          }
        );
        // this.$router.push("/expedientes/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      if (this.error !== this.mensageError) {
        await this.cleanFields();
      }
      await this.fillFields();
      document.getElementById(this.campoFocus).focus();
      this.toggleAlert();
    },

    async deleteAllExpedientes() {
      try {
        const res = await eliminateExpedientes(this.expediente);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    cleanFields() {
      this.expediente.nombre = "";
      this.expediente.referidoPor = "";
      this.expediente.fechaActual = "";
      this.expediente.fechaNacimiento = "";
      this.expediente.edad = "";
      this.expediente.sexo = "";
      this.expediente.cedula = "";
      this.expediente.telefono = "";
      this.expediente.direccion = "";
      this.expediente.seguro = "";
      this.expediente.oficio = "";
      this.expediente.deporte = "";
      this.expediente.ladoDominante = "";
      this.expediente.app = "";
      this.expediente.habitosToxicos = "";
      this.expediente.antQuirurgicos = "";
      this.expediente.alergias = "";
      this.expediente.mecanismoLesion = "";
      this.expediente.tiempoEvolucion = "";
      this.expediente.elevacionAnteriorActiva = "";
      this.expediente.elevacionAnteriosPasiva = "";
      this.expediente.rotacionInterna = "";
      this.expediente.rotacionExterna = "";
      this.expediente.abd = "";
      this.expediente.jobe = "";
      this.expediente.speed = "";
      this.expediente.obrien = "";
      this.expediente.naponeon = "";
      this.expediente.acromioClavicular = "";
      this.expediente.inestabilidadAnterior = "";
      this.expediente.inestabilidadPosterior = "";
      this.expediente.cajonAnterior = "";
      this.expediente.cajonPosterior = "";
      this.expediente.lachman = "";
      this.expediente.appley = "";
      this.expediente.bostezoLateral = "";
      this.expediente.bostezoMedial = "";
      this.expediente.otraLesion = "";
      this.expediente.estudiosSolicitados = "";
      this.expediente.diagnosticoPresuntivo = "";
      this.expediente.diagnosticoImagen = "";
      this.expediente.medicamentos = "";
      this.expediente.terapiaFisica = "";
      this.expediente.cirugia = "";
      this.expediente.consultas = "";
      this.expediente.aflNSS = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById(this.campoFocus).focus();
    },
    focus2() {
      document.getElementById(this.campoFocus2).focus();
    },
  },
});
