
const filters = ["Todos", "Power User", "Médico", "Secretaria"];

export default {
  props: ["filterUsuarios", "search", "filteredUsuarios"],

  data() {
    return {
      filters,
      term: "",
      filters2: [] as [],
    };
  },
};
