
import { getExpedientes } from "@/services/expedientesclinicos/Expediente";
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Licencia } from "@/interfaces/Licencia";
import { Funcion } from "@/interfaces/Funcion";
import { Vitola } from "@/interfaces/Vitola";
import { Empleado } from "@/interfaces/Empleado";
import {
  getPeg,
  getMez,
  getEmp,
} from "@/services/expedientesclinicos/Empleado";
import { getLigas } from "@/services/expedientesclinicos/Liga";
import {
  createLicencia,
  eliminateLicencias,
  createLicenciaa,
  getOneLicencia,
  deleteLicencia,
  getLicencia,
  updateLicencia,
  getPrint,
} from "@/services/expedientesclinicos/Licencia";
import { getVitolas } from "@/services/expedientesclinicos/Vitola";
import { getFuncions } from "@/services/expedientesclinicos/Funcion";
import { createMensaje } from "@/services/expedientesclinicos/ChatService";
import numeral from "numeral";
import moment from "moment";
// import Pusher from "pusher-js";

export default defineComponent({
  name: "licencias-form",
  components: {
    Navbar,
  },
  data() {
    return {
      search: "",
      seguros2: [],
      seguros: [
        "NINGUNO",
        "ARS CMD",
        "MAPFRE SALUD ARS, S.A.",
        "FUTURO",
        "META SALUD",
        "YUNEN",
        "RESERVAS",
        "MONUMENTAL",
        "ASEMAP",
        "ARS APS S A",
        "SIMAG",
        "RENACER",
        "GRUPO MEDICO ASOCIADO",
        "PRIMERA ARS DE HUMANO",
        "UNIVERSAL",
        "HUMANO SEGUROS",
        "SENASA CONTRIBUTIVO",
        "SENASA SUBSIDIADO",
        "SEMMA",
        "IDOPPRIL",
        "ARS UASD",
        "OTRA",
      ],
      currentEdition: {} as any,
      vitolas: [] as Vitola[],
      empleados: [] as Empleado[],
      empleadosEmp: [] as Empleado[],
      empleadosPeg: [] as Empleado[],
      empleadosMez: [] as Empleado[],
      campoFocus: "licencia",
      mensageError: "Error",
      mensageExito: "Licencia Registrada Exitosamente",
      mensageConfirm: "¿Está Seguro que desea Eliminar Esta Licencia?",
      mensageConfirmRenew: "¿Está Seguro que desea Renovar Esta Licencia?",
      encabezado: "",
      modoForm: "",
      funciones: [] as Funcion[],
      showDatosPadre: false,
      showDatosMadre: false,
      showDatosTutor: false,
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      licencia: {} as Licencia,
      oneLicencia: {} as Licencia,
      one: {},
      nextNo: Number,
      medicoSelected: [],
      clienteSelected: [],
      descripcionCliente: "",

      showInComentary: "",
      showModal: false,
      showModal7: false,
      showDelete: false,
      currentConsulta: "",
      estado: {} as object,
      printId: {} as any,
    };
  },

  async mounted() {
    // this.$store.state.user.crrntPcte = "YULISKA ALTAGRACIA CANERA";
    if (this.$route.fullPath == "/licencias/new") {
      this.modoForm = "add";
      this.encabezado = "Nueva Licencia";
    } else {
      this.modoForm = "show";
      this.encabezado = "Detalles de Licencia";
    }

    if (this.modoForm == "add") {
      this.licencia.no = 1;
      this.licencia.consultas = [];
      this.fillFields();
      this.fixTimeAdd();
    } else {
      this.showDeleteMethod();
      if (typeof this.$route.params.id === "string") {
        await this.loadLicencia(this.$route.params.id);
      }
      this.fixTime();
    }

    this.focus();
    this.loadExpedientes();
    // this.loadEmpleados();
    // this.loadLigas();
    // this.loadVitolas();
    // this.filterEmpleados();
  },

  methods: {
    async loadExpedientes() {
      this.toggleLoading();
      try {
        const res = await getExpedientes(this.$store.state.user.puedeVer);
        this.seguros = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    setSeguro(seguro: string, cedula: string, edad: number) {
      this.licencia.nombre = seguro;
      this.licencia.edad = edad;
      this.licencia.seguro = cedula;

      this.licencia.licencia = `Por medio de la presente hacemos constar que el/la paciente ${this.licencia.nombre}, identificación No. ${this.licencia.seguro}, presenta el/los Diagnóstico(s) de __________________________, por lo que recomendamos _____________________________, a los fines de contribuir con su recuperación.`;

      this.showModalMethod7();
      this.search = "";
      document.getElementById("licencia").focus();
    },

    buscar(term: string) {
      this.seguros2 = this.seguros;
      if (term !== "") {
        this.seguros2 = this.seguros.filter((seguro: any) => {
          return seguro.nombre
            .toLowerCase()
            .includes(this.search.toLowerCase());
        });
      }
      if (this.seguros2.length == 1) {
        if (confirm("¿" + this.seguros2[0].nombre + "?")) {
          this.setSeguro(this.seguros2[0].nombre, this.seguros2[0].edad);
          // document.getElementById("oficio").focus();
        }
      }
    },

    showModalMethod7() {
      this.seguros2 = this.seguros;
      this.showModal7 = !this.showModal7;
      // document.getElementById("oficio").focus();
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    deleteService(it: any) {
      if (confirm("Está Seguro que desea Eliminar Esta Consulta?")) {
        this.licencia.consultas.splice(it, 1);
      }
    },

    editService(index: number, consulta: any) {
      this.currentEdition = consulta;
      this.showModalMethod();
    },

    newFormatDate(dateValue: Date) {
      // let out = moment(dateValue).add(0, "h");
      // return moment(out).format("DD/MM/YYYY");
      moment.locale("es");
      return moment(dateValue).calendar();
      // .startOf("hour")
      // .fromNow();
    },

    async addActivity() {
      if (this.currentConsulta !== "") {
        this.licencia.consultas.push({
          description: this.currentConsulta,
          date: new Date(),
        });
        this.currentConsulta = "";
      }
      document.getElementById("licencia").focus();
    },

    calcularEdad(date: any) {
      let years = 0;
      let edad = Math.floor(
        moment(new Date()).diff(moment(date, "YYYY-MM-DD"), "years", true)
      );
      if (edad > 120 || edad < 0) {
        years = 0;
      } else {
        years = edad;
      }
      this.licencia.edad = years;
    },

    async filterEmpleados() {
      this.toggleLoading();
      try {
        const res = await getPeg();
        this.empleadosPeg = res.data;
      } catch (error) {
        // console.error(error);
      }

      try {
        const res = await getEmp();
        this.empleadosEmp = res.data;
      } catch (error) {
        // console.error(error);
      }

      try {
        const res = await getMez();
        this.empleadosMez = res.data;
      } catch (error) {
        // console.error(error);
      }

      this.toggleLoading();
    },

    async loadLigas() {
      // this.toggleLoading();
      try {
        const res = await getLigas();
        this.ligas = res.data;
      } catch (error) {
        // console.error(error);
      }
      // this.toggleLoading();
    },

    async loadVitolas() {
      // this.toggleLoading();
      try {
        const res = await getVitolas();
        this.vitolas = res.data;
      } catch (error) {
        // console.error(error);
      }
      // this.toggleLoading();
    },

    // async loadEmpleados() {
    //   this.toggleLoading();
    //   try {
    //     const res = await getEmpleados();
    //     this.empleados = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   this.toggleLoading();
    // },

    fixTimeAdd() {
      this.licencia.fechaActual = this.formatDateToFixAdd(
        this.licencia.fechaActual,
        true
      );
    },

    fixTime() {
      this.licencia.fecha = this.formatDateToFix(this.licencia.fecha, true);
      this.licencia.fechaActual = this.formatDateToFix(
        this.licencia.fechaActual,
        true
      );
    },

    formatDateToFixAdd(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "h");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DD");
      }
    },

    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(4, "h");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DD");
      }
    },

    async loadLicencia(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getLicencia(id);
        this.licencia = data;
        // this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },

    async handleUpdate() {
      this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.licencia.userMod = this.$store.state.user.usuario;
          await updateLicencia(this.$route.params.id, this.licencia);
          this.addMessage();
          this.$router.push("/licencias");
        }
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
      // this.toggleAlert();
    },

    async handleRenew() {
      if (confirm(this.mensageConfirmRenew)) {
        try {
          await this.fillFields();
          await this.saveLicencia();
          this.addMessage();
          // this.$router.push("/licencias");
        } catch (error) {
          //console.error(error);
        }
      }
    },

    async handleDelete() {
      if (confirm(this.mensageConfirm)) {
        try {
          if (typeof this.$route.params.id === "string") {
            await deleteLicencia(this.$route.params.id);
            this.addMessage();
            this.$router.push("/licencias");
          }
        } catch (error) {
          //console.error(error);
        }
      }
    },

    showDeleteMethod() {
      if (this.$store.state.user.type == "Power User") {
        this.showDelete = true;
      }
    },

    toggleShowDatosPadre() {
      this.showDatosPadre = !this.showDatosPadre;
    },
    toggleShowDatosMadre() {
      this.showDatosMadre = !this.showDatosMadre;
    },
    toggleShowDatosTutor() {
      this.showDatosTutor = !this.showDatosTutor;
    },

    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    isError(message: string) {
      if (message == this.mensageExito) {
        return "success";
      } else {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcLicencia() {
      (this.licencia.retension = this.licencia.bruto * 0.1),
        (this.licencia.neto = this.licencia.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fillFields() {
      this.licencia.fechaActual = new Date();
      this.licencia.fecha = new Date();
    },

    async loadOneLicencia() {
      try {
        const res = await getOneLicencia();
        this.oneLicencia = res.data;
        this.one = this.oneLicencia[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        if (this.nextNo == null) {
          this.nextNo = 0;
        }
        this.licencia.no = this.nextNo;
        this.licencia.principal = this.nextNo;
        this.licencia.principal = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async loadPrint() {
      try {
        const res = await getPrint();
        this.printId = res.data;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveLicenciaa() {
      await this.loadOneLicencia();
      try {
        const res = await createLicenciaa(this.servicio);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveLicencia() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneLicencia();
          this.oneLicencia = res.data;
          this.one = this.oneLicencia[0];
          this.nextNo = this.one.no + 1;
          this.licencia.no = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.licencia.userReg = this.$store.state.user.usuario;
        const res = await createLicencia(this.licencia).then(
          (res) => {
            this.error = this.respuesta = res.data.title;
            // this.$router.push("/");
            this.res = res;
            this.respuesta = res.data;
            this.addMessage();
          },
          (err) => {
            // console.log(err.response);
            this.error = err.response.data.error;
          }
        );
        // this.$router.push("/licencias/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      if (this.error !== this.mensageError) {
        await this.cleanFields();
      }
      await this.fillFields();
      document.getElementById(this.campoFocus).focus();
      this.toggleAlert();
      // Impresion Directa
      await this.loadPrint();
      this.$router.push(`/licencias2/${this.printId._id}`);
    },

    async deleteAllLicencias() {
      try {
        const res = await eliminateLicencias(this.licencia);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    cleanFields() {
      this.licencia.nombre = "";
      this.licencia.referidoPor = "";
      this.licencia.fechaActual = "";
      this.licencia.fechaNacimiento = "";
      this.licencia.edad = "";
      this.licencia.sexo = "";
      this.licencia.cedula = "";
      this.licencia.telefono = "";
      this.licencia.direccion = "";
      this.licencia.seguro = "";
      this.licencia.oficio = "";
      this.licencia.deporte = "";
      this.licencia.ladoDominante = "";
      this.licencia.app = "";
      this.licencia.habitosToxicos = "";
      this.licencia.antQuirurgicos = "";
      this.licencia.alergias = "";
      this.licencia.mecanismoLesion = "";
      this.licencia.tiempoEvolucion = "";
      this.licencia.elevacionAnteriorActiva = "";
      this.licencia.elevacionAnteriosPasiva = "";
      this.licencia.rotacionInterna = "";
      this.licencia.rotacionExterna = "";
      this.licencia.abd = "";
      this.licencia.jobe = "";
      this.licencia.speed = "";
      this.licencia.obrien = "";
      this.licencia.naponeon = "";
      this.licencia.acromioClavicular = "";
      this.licencia.inestabilidadAnterior = "";
      this.licencia.inestabilidadPosterior = "";
      this.licencia.cajonAnterior = "";
      this.licencia.cajonPosterior = "";
      this.licencia.lachman = "";
      this.licencia.appley = "";
      this.licencia.bostezoLateral = "";
      this.licencia.bostezoMedial = "";
      this.licencia.otraLesion = "";
      this.licencia.estudiosSolicitados = "";
      this.licencia.diagnosticoPresuntivo = "";
      this.licencia.diagnosticoImagen = "";
      this.licencia.medicamentos = "";
      this.licencia.terapiaFisica = "";
      this.licencia.cirugia = "";
      this.licencia.consultas = "";
      this.licencia.licencia = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById(this.campoFocus).focus();
    },
    focus2() {
      document.getElementById(this.campoFocus2).focus();
    },
  },
});
