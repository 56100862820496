
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Liga } from "@/interfaces/Liga";
import { Funcion } from "@/interfaces/Funcion";
import {
  createLiga,
  eliminateLigas,
  createLigaa,
  getOneLiga,
  deleteLiga,
  getLiga,
  updateLiga,
} from "@/services/expedientesclinicos/Liga";
import { getFuncions } from "@/services/expedientesclinicos/Funcion";
import { createMensaje } from "@/services/expedientesclinicos/ChatService";
import numeral from "numeral";
import moment from "moment";
// import Pusher from "pusher-js";

export default defineComponent({
  name: "ligas-form",
  components: {
    Navbar,
  },
  data() {
    return {
      campoFocus: "descripcion",
      mensageError: "Ya Existe un Liga Registrada con esta Descripción",
      mensageExito: "Liga Registrada Exitosamente",
      encabezado: "",
      modoForm: "",
      funciones: [] as Funcion[],
      showDatosPadre: false,
      showDatosMadre: false,
      showDatosTutor: false,
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      liga: {} as Liga,
      oneLiga: {} as Liga,
      one: {},
      nextNo: Number,
      medicoSelected: [],
      clienteSelected: [],
      descripcionCliente: "",

      showInComentary: "",
      showModal: false,
      showDelete: false,
      currentActivity: "",
      estado: {} as object,
    };
  },

  async mounted() {
    if (this.$route.fullPath == "/ligas/new") {
      this.modoForm = "add";
      this.encabezado = "Nueva Liga";
    } else {
      this.modoForm = "show";
      this.encabezado = "Detalles de Liga";
    }

    if (this.modoForm == "add") {
      this.liga.no = 1;
      this.fillFields();
    } else {
      this.showDeleteMethod();
      if (typeof this.$route.params.id === "string") {
        await this.loadLiga(this.$route.params.id);
      }
    }

    this.focus();
  },

  methods: {
    arreglar() {
      this.liga.descripcion = this.liga.descripcion.trim();
    },

    async loadLiga(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getLiga(id);
        this.liga = data;
        // this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },

    async handleUpdate() {
      // this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.liga.userMod = this.$store.state.user.usuario;
          await updateLiga(this.$route.params.id, this.liga);
          this.addMessage();
          this.$router.push("/ligas");
        }
      } catch (error) {
        //console.error(error);
      }
      // this.toggleLoading();
      // this.toggleAlert();
    },

    async handleDelete() {
      if (confirm("¿Está Seguro que desea Eliminar Este Liga?")) {
        try {
          if (typeof this.$route.params.id === "string") {
            await deleteLiga(this.$route.params.id);
            this.addMessage();
            this.$router.push("/ligas");
          }
        } catch (error) {
          //console.error(error);
        }
      }
    },

    showDeleteMethod() {
      if (this.$store.state.user.type == "Power User") {
        this.showDelete = true;
      }
    },

    toggleShowDatosPadre() {
      this.showDatosPadre = !this.showDatosPadre;
    },
    toggleShowDatosMadre() {
      this.showDatosMadre = !this.showDatosMadre;
    },
    toggleShowDatosTutor() {
      this.showDatosTutor = !this.showDatosTutor;
    },

    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    isError(message: string) {
      if (message == this.mensageExito) {
        return "success";
      } else {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcLiga() {
      (this.liga.retension = this.liga.bruto * 0.1),
        (this.liga.neto = this.liga.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fillFields() {
      this.liga.modalidad = "Jornalero";
      this.liga.metodoPago = "Efectivo";
    },

    async loadOneLiga() {
      try {
        const res = await getOneLiga();
        this.oneLiga = res.data;
        this.one = this.oneLiga[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        if (this.nextNo == null) {
          this.nextNo = 0;
        }
        this.liga.no = this.nextNo;
        this.liga.principal = this.nextNo;
        this.liga.principal = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveLigaa() {
      await this.loadOneLiga();
      try {
        const res = await createLigaa(this.servicio);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveLiga() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneLiga();
          this.oneLiga = res.data;
          this.one = this.oneLiga[0];
          this.nextNo = this.one.no + 1;
          this.liga.no = this.nextNo;
          this.liga.principal = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.liga.userReg = this.$store.state.user.usuario;
        this.arreglar();
        const res = await createLiga(this.liga).then(
          (res) => {
            this.error = this.respuesta = res.data.title;
            // this.$router.push("/");
            this.res = res;
            this.respuesta = res.data;
            this.addMessage();
          },
          (err) => {
            // console.log(err.response);
            this.error = err.response.data.error;
          }
        );
        // this.$router.push("/ligas/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      if (this.error !== this.mensageError) {
        await this.cleanFields();
      }
      await this.fillFields();
      document.getElementById(this.campoFocus).focus();
      this.toggleAlert();
    },

    async deleteAllLigas() {
      try {
        const res = await eliminateLigas(this.liga);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    cleanFields() {
      this.liga.descripcion = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById(this.campoFocus).focus();
    },
  },
});
