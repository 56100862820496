
import { defineComponent } from "vue";
import { Liga } from "@/interfaces/Liga";
import {
  deleteLiga,
  getLiga,
  updateLiga,
} from "@/services/expedientesclinicos/Liga";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "liga-list",
  data() {
    return {
      cargando: false,
      liga: {} as Liga,
    };
  },
  methods: {
    toPrint() {
      window.print();
    },
    toggleLoading() {
      this.cargando = !this.cargando;
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("00000000");
    },
    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "hours");
      return moment(out).format("DD/MM/YYYY");
    },
    async loadLiga(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getLiga(id);
        this.liga = data;
      } catch (error) {
        //console.error(error);
      }
      await this.toggleLoading();
      this.toPrint();

      if (this.$store.state.ids.length == 0) {
        this.$router.push(`/ligas/${this.liga._id}`);
      } else {
        this.$router.push(`/ligaslot/new`);
      }

      // this.$router.push(`/ligas/${this.liga._id}`);
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateLiga(this.$route.params.id, this.liga);
          this.$router.push("/ligas");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteLiga(this.$route.params.id);
          this.$router.push("/ligas");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadLiga(this.$route.params.id);
    }
  },

  // beforeUnmount() {
  //   this.toPrint();
  // }
});
