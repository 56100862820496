
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Funcion } from "@/interfaces/Funcion";
import {
  createFuncion,
  eliminateFuncions,
  createFunciona,
  getOneFuncion,
  deleteFuncion,
  getFuncion,
  updateFuncion,
} from "@/services/expedientesclinicos/Funcion";
import { createMensaje } from "@/services/expedientesclinicos/ChatService";
import numeral from "numeral";
import moment from "moment";
import Pusher from "pusher-js";

export default defineComponent({
  name: "funcions-form",
  components: {
    Navbar,
  },
  data() {
    return {
      showDatosPadre: false,
      showDatosMadre: false,
      showDatosTutor: false,
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      funcion: {} as Funcion,
      oneFuncion: {} as Funcion,
      one: {},
      nextNo: Number,
      medicoSelected: [],
      clienteSelected: [],
      descripcionCliente: "",

      showInComentary: "",
      showModal: false,
      showDelete: false,
      currentActivity: "",
      estado: {} as object,
    };
  },

  mounted() {
    this.funcion.no = 1;
    // this.defFields();

    // this.showDeleteMethod();
    // if (typeof this.$route.params.id === "string") {
    //   this.loadFuncion(this.$route.params.id);
    // }

    // this.pusherSubscribe();

    this.focus();
  },

  methods: {
        async loadFuncion(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getFuncion(id);
        this.funcion = data;
        // this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },
    
    async handleUpdate() {
      // this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.funcion.userMod = this.$store.state.user.usuario;
          await updateFuncion(this.$route.params.id, this.funcion);
          this.addMessage();
          this.$router.push("/funcions");
        }
      } catch (error) {
        //console.error(error);
      }
      // this.toggleLoading();
      // this.toggleAlert();
    },

    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          await deleteFuncion(this.$route.params.id);
          this.addMessage();
          this.$router.push("/funcions");
        }
      } catch (error) {
        //console.error(error);
      }
    },

    pusherSubscribe() {
      // Start pusher subscribe
      var pusher = new Pusher("d7b50b87118775ed0b11", {
        cluster: "us2",
      });

      var channel = pusher.subscribe("my-channel");
      channel.bind("my-event", (data: any) => {
        if (typeof this.$route.params.id === "string") {
          this.loadFuncion2(this.$route.params.id);
        }
        // this.player.src = this.song.src;
        // this.player.play();
      });
      // End pusher subscribe
    },

    showDeleteMethod() {
      if (this.$store.state.user.type == "Power User") {
        this.showDelete = true;
      }
    },

    toggleShowDatosPadre() {
      this.showDatosPadre = !this.showDatosPadre;
    },
    toggleShowDatosMadre() {
      this.showDatosMadre = !this.showDatosMadre;
    },
    toggleShowDatosTutor() {
      this.showDatosTutor = !this.showDatosTutor;
    },

    calcularEdad(date: any) {
      let years = 0;
      let edad = Math.floor(
        moment(new Date()).diff(moment(date, "YYYY-MM-DD"), "years", true)
      );
      if (edad > 120 || edad < 0) {
        years = 0;
      } else {
        years = edad;
      }
      this.funcion.edaddelfuncion = years;
    },

    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DTHH:mm");
      } else {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-D");
      }
    },

    fixTime() {
      this.funcion.fecha_ingreso = this.formatDateToFix(
        this.funcion.fecha_ingreso,
        true
      );
    },

    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    // async getFuncion() {
    //   this.toggleLoading();
    //   this.documento.idfact = this.funcion.idfact;
    //   if (this.documento) {
    //     try {
    //       const res = await getFuncion(this.documento);
    //       // const res = await getFuncions();
    //       // this.funcion = res.data;
    //       // Asignar Campos Seleccionandolos
    //       this.funcion.idfact = res.data.idfact;
    //       this.funcion.id_ars = res.data.id_ars;
    //       this.funcion.nom = res.data.nom;
    //       this.funcion.nro_autorizacion_salida =
    //         res.data.nro_autorizacion_salida;
    //       this.funcion.fecha_ingreso = res.data.fecha_ingreso;
    //       this.funcion.numero_afiliado = res.data.numero_afiliado;
    //       this.funcion.rnc = res.data.rnc;
    //       this.funcion.tipo_funcion = res.data.tipo_funcion;
    //       this.funcion.cobertura = res.data.cobertura;
    //       this.funcion.total_servicio = res.data.total_servicio;

    //       this.funcion.status = this.$store.state.user.defaultStatus;
    //       this.funcion.actividad = [];
    //       this.funcion.actividad.push({
    //         description: this.$store.state.user.defaultStatus,
    //         date: new Date(),
    //         user: this.$store.state.user.usuario,
    //         detalles: "",
    //       });
    //       this.fixTime();
    //     } catch (error) {
    //       // console.error(error);
    //     }
    //   }
    //   await this.toggleLoading();
    //   if (this.funcion.cobertura == 0) {
    //     // this.saveFuncion();
    //     // alert("Funcion Encontrada");
    //     alert("Funcion no Encontrada");
    //     this.focus();
    //   }
    // },

    isError(message: string) {
      if (message == "Funcion Registrada Exitosamente") {
        return "success";
      } else if (
        message == "Ya Existe una Funcion Registrada con esta Descripción"
      ) {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcFuncion() {
      (this.funcion.retension = this.funcion.bruto * 0.1),
        (this.funcion.neto = this.funcion.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    // defFields() {
    // this.funcion.status = this.$store.state.user.defaultStatus;
    // this.actividad = "4 - Recibido por Reclamaciones Médicas";
    // this.funcion.actividad.push(this.actividad);
    // },

    async loadOneFuncion() {
      try {
        const res = await getOneFuncion();
        this.oneFuncion = res.data;
        this.one = this.oneFuncion[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        if (this.nextNo == null) {
          this.nextNo = 0;
        }
        this.funcion.no = this.nextNo;
        this.funcion.principal = this.nextNo;
        this.funcion.principal = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveFunciona() {
      await this.loadOneFuncion();
      try {
        const res = await createFunciona(this.servicio);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveFuncion() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneFuncion();
          this.oneFuncion = res.data;
          this.one = this.oneFuncion[0];
          this.nextNo = this.one.no + 1;
          this.funcion.no = this.nextNo;
          this.funcion.principal = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.funcion.status = this.$store.state.user.defaultStatus;
        this.funcion.userReg = this.$store.state.user.usuario;
        const res = await createFuncion(this.funcion).then(
          (res) => {
            this.error = this.respuesta = res.data.title;
            // this.$router.push("/");
            this.res = res;
            this.respuesta = res.data;
            this.addMessage();
          },
          (err) => {
            // console.log(err.response);
            this.error = err.response.data.error;
          }
        );
        // this.$router.push("/funcions/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      await this.definingFields();
      // await this.defFields();
      document.getElementById("descripcion").focus();
      this.toggleAlert();
    },

    async deleteAllFuncions() {
      try {
        const res = await eliminateFuncions(this.funcion);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    definingFields() {
      this.funcion.descripcion = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById("descripcion").focus();
    },
  },
});
