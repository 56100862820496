
import Filters from "@/components/Cxp/FiltersComponent.vue";
import ListadoComponent from "@/components/Cxp/ListComponent.vue";
import Navbar from "@/components/Navbar.vue";
import { Cxp } from "@/interfaces/Cxp";
import { getCxps } from "@/services/expedientesclinicos/Cxp";
import Pusher from "pusher-js";

export default {
  name: "App",

  components: {
    Filters,
    ListadoComponent,
    Navbar,
  },

  data() {
    return {
      song: {
        title: "Notification",
        src: require("@/assets/sounds/notification.mp3"),
      },
      player: new Audio(),
      showModal: false,
      cargando: false,
      cxps: [] as Cxp[],
      str: "",
      type: "",
    };
  },

  methods: {
    pusherSubscribe() {
      // Start pusher subscribe
      var pusher = new Pusher("d7b50b87118775ed0b11", {
        cluster: "us2",
      });

      var channel = pusher.subscribe("my-channel");
      channel.bind("my-event", (data: any) => {
        this.loadCxps2();
        // this.player.src = this.song.src;
        // this.player.play();
      });
      // End pusher subscribe
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },
    async filterCxps(catName: string) {
      try {
        const res = await getCxps();
        this.cxps = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (catName !== "Todos") {
        this.cxps = this.cxps.filter((cxp: Cxp) => {
          return cxp.empleado === catName;
        });
      }
    },

    async search(term: string) {
      this.toggleLoading();
      try {
        const res = await getCxps();
        this.cxps = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (term !== "Todos") {
        this.cxps = this.cxps.filter((cxp: Cxp) => {
          return cxp.empleado.toLowerCase().includes(term.toLowerCase());
        });
      }
      this.toggleLoading();
    },

    async loadCxps() {
      this.toggleLoading();
      try {
        const res = await getCxps();
        this.cxps = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    async loadCxps2() {
      this.toggleLoading();
      try {
        const res = await getCxps();
        this.cxps = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },
  },

  mounted() {
    this.loadCxps();
    this.pusherSubscribe();
  },
};
