
import { defineComponent } from "vue";
import { Reclamo } from "@/interfaces/Reclamo";
import {
  deleteReclamo,
  getReclamo,
  updateReclamo,
} from "@/services/expedientesclinicos/Reclamo";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "reclamo-list",
  data() {
    return {
      fechaActual: new Date(),
      cargando: false,
      reclamo: {} as Reclamo,
    };
  },
  methods: {
    toPrint() {
      window.print();
    },
    toggleLoading() {
      this.cargando = !this.cargando;
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },

    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("D/MM/yyyy");
    },

    formatTime(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("hh:mm a");
    },

    formatDate2(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("D/MM/yyyy");
    },

    async loadReclamo(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getReclamo(id);
        this.reclamo = data;
      } catch (error) {
        //console.error(error);
      }
      await this.toggleLoading();
      this.toPrint();

      if (this.$store.state.ids.length == 0) {
        this.$router.push(`/reclamos/${this.reclamo._id}`);
      } else {
        this.$router.push(`/reclamoslot/new`);
      }

      this.$router.push(`/reclamos/${this.reclamo._id}`);
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateReclamo(this.$route.params.id, this.reclamo);
          this.$router.push("/reclamos");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteReclamo(this.$route.params.id);
          this.$router.push("/reclamos");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadReclamo(this.$route.params.id);
    }
  },

  // beforeUnmount() {
  //   this.toPrint();
  // }
});
