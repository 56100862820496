
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Lote } from "@/interfaces/Lote";
// import { Funcion } from "@/interfaces/Funcion";
// import { Vitola } from "@/interfaces/Vitola";
// import { Lote } from "@/interfaces/Lote";
// import {
//   getPeg,
//   getMez,
//   getEmp,
// } from "@/services/expedientesclinicos/Lote";
// import { getLigas } from "@/services/expedientesclinicos/Liga";
import {
  createLote,
  eliminateLotes,
  createLotea,
  getOneLote,
  deleteLote,
  getLote,
  updateLote,
} from "@/services/expedientesclinicos/Lote";
import { GetByAflNSS } from "@/services/expedientesclinicos/Expediente";
// import { getVitolas } from "@/services/expedientesclinicos/Vitola";
// import { getFuncions } from "@/services/expedientesclinicos/Funcion";
import { createMensaje } from "@/services/expedientesclinicos/ChatService";
import { getLotes } from "@/services/expedientesclinicos/Lote";
import numeral from "numeral";
import moment from "moment";
// import Pusher from "pusher-js";

export default defineComponent({
  name: "lotes-form",
  components: {
    Navbar,
  },
  data() {
    return {
      lotes: [] as Lote[],
      subs: [],
      preSubs: [],
      almacenes: [
        {
          desc: "Almacén No. 01 (General)",
          subs: ["Donaciones", "Venta / Servicios", "Muestras Médicas"],
        },
        {
          desc: "Almacén No. 02 (Suministro de Oficina y Limpieza)",
          subs: [
            "Sub-01 (Materiales de Oficina)",
            "Sub-02 (Materiales de Limpieza)",
          ],
        },
        {
          desc: "Almacén No. 03 (Abastecimiento)",
          subs: [
            "Sub-01 (Carro de Paro UCI)",
            "Sub-02 (Carro de Paro 2do Piso)",
            "Sub-03 (Carro de Paro Perinato)",
            "Sub-04 (Carro de Paro Quirófano)",
            "Sub-05 (Carro de Paro 3er Piso)",
          ],
        },
        {
          desc: "Almacén No. 04 (Emergencia, Quirófano y Ambulatorio)",
          subs: [
            "Sub-01 (Emergencia)",
            "Sub-02 (Depósito Quirófano)",
            "Sub-03 (Imágenes)",
            "Sub-04 (Laboratorio Clínico)",
            "Sub-05 (Laboratorio Biomolecular)",
            "Sub-06 (Farmacia 3er Piso)",
            "Sub-07 (Emergencia Pediatrica)",
          ],
        },
        {
          desc: "Almacén No. 70 (Cocina)",
          subs: [
            "Sub-01 (Alimentos)",
            "Sub-02 (Bebidas y Líquidos)",
            "Sub-03 (Suministros)",
            "Sub-04 (Utencilios)",
          ],
        },
        {
          desc: "Almacén No. 98 (Vencidos)",
          subs: [],
        },
        {
          desc: "Almacén No. 99 (Averiado)",
          subs: [],
        },
      ],
      num1: 0,
      num2: 0,
      fechaActual: "",
      search: "",
      seguros2: [],
      seguros: [
        "NINGUNO",
        "ARS CMD",
        "MAPFRE SALUD ARS, S.A.",
        "FUTURO",
        "META SALUD",
        "YUNEN",
        "RESERVAS",
        "MONUMENTAL",
        "ASEMAP",
        "ARS APS S A",
        "SIMAG",
        "RENACER",
        "GRUPO MEDICO ASOCIADO",
        "PRIMERA ARS DE HUMANO",
        "UNIVERSAL",
        "HUMANO SEGUROS",
        "SENASA CONTRIBUTIVO",
        "SENASA SUBSIDIADO",
        "SEMMA",
        "IDOPPRIL",
        "ARS UASD",
        "OTRA",
      ],
      currentEdition: {} as any,
      // vitolas: [] as Vitola[],
      // lotes: [] as Lote[],
      // lotesEmp: [] as Lote[],
      // lotesPeg: [] as Lote[],
      // lotesMez: [] as Lote[],
      campoFocus: "aflNSS",
      mensageError:
        "Ya Existe un Lote Registrado con este Número de Autorización",
      mensageExito: "Lote Registrado Exitosamente",
      mensageConfirm: "¿Está Seguro que desea Eliminar Este Lote?",
      encabezado: "",
      modoForm: "",
      // funciones: [] as Funcion[],
      showDatosPadre: false,
      showDatosMadre: false,
      showDatosTutor: false,
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      showMessage: false,
      loadedAfiliado: {},
      cargando: false,
      showSpinner: false,
      lote: {} as Lote,
      oneLote: {} as Lote,
      one: {},
      nextNo: Number,
      loteSelected: [],
      clienteSelected: [],
      descripcionCliente: "",

      showInComentary: "",
      showModal: false,
      showModal7: false,
      showModalAseguradoras: false,
      showDelete: false,
      currentConsulta: "",
      estado: {} as object,
    };
  },

  async mounted() {
    // this.fechaActual = new Date();
    // this.loadLotes();
    // this.seguros = [
    //   "Victor Osiris Alcántara Castro",
    //   "Abel David Ventura Rivas",
    // ];
    if (this.$route.fullPath == "/lotesciru/new") {
      this.modoForm = "add";
      this.encabezado = "Nueva Reclamación por Cirugía";
    } else {
      this.modoForm = "show";
      this.encabezado = "Detalles de Reclamación por Cirugía";
    }

    if (this.modoForm == "add") {
      this.lote.no = 1;
      this.lote.consultas = [];
      this.fillFields();
      this.fixTime();
    } else {
      this.showDeleteMethod();
      if (typeof this.$route.params.id === "string") {
        await this.loadLote(this.$route.params.id);
        // this.defSubs();

        // this.lote.finVac = new Date();
        // this.lote.fechaNac = new Date();
        // this.lote.fechaCont = new Date();
      }
      this.fixTime();
    }

    this.focus();
    // this.loadLotes();
    // this.loadLigas();
    // this.loadVitolas();
    // this.filterLotes();
  },

  methods: {
    showMessageMethod() {
      this.showMessage = !this.showMessage;
    },
    setService() {
      if (this.lote.tipServ == "Consulta") {
        this.lote.descServ = "Consulta";
      } else {
        this.lote.descServ = "";
      }
    },

    showModalAseguradorasMethod() {
      this.seguros2 = this.seguros;
      this.showModalAseguradoras = !this.showModalAseguradoras;
    },

    async redirect(id: string) {
      // alert("Redirecting");
      this.$router.push(`/lotes/${id}`);

      // Remounted
      this.modoForm = "show";
      this.encabezado = "Detalles de Lote";
      this.showDeleteMethod();
      await this.loadLote(id);
      this.defSubs();

      // this.lote.finVac = new Date();
      // this.lote.fechaNac = new Date();
      // this.lote.fechaCont = new Date();
      this.fixTime();
      this.focus();

      this.showModalMethod7();
    },

    async buscando() {
      this.toggleLoading();
      try {
        const res = await getLotes(this.criterio);
        this.lotes = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (this.lote.barcode !== "Todos") {
        this.lotes = this.lotes.filter((lote: Lote) => {
          return lote.nombPac
            .toLowerCase()
            .includes(this.lote.barcode.toLowerCase());
        });
      }
      this.toggleLoading();
    },

    async changeFocus() {
      document.getElementById("fechAutor").focus();
      await this.buscando();
      this.showModalMethod7();
    },
    calcVenc(date: any) {
      this.lote.mesVenc = moment(date).format("MM");
      this.lote.anoVenc = moment(date).format("YYYY");
    },

    // defSubs() {
    //   this.preSubs = this.almacenes.filter((almacen: Lote) => {
    //     return almacen.desc
    //       .toLowerCase()
    //       .includes(this.lote.almacn.toLowerCase());
    //   });
    //   this.subs = this.preSubs[0].subs;
    // },

    setSeguro(seguro: string) {
      this.lote.nombARS = seguro;
      this.showModalAseguradorasMethod();
      this.search = "";
      document.getElementById("fechAutor").focus();
    },

    buscar(term: string) {
      this.seguros2 = this.seguros;
      if (term !== "") {
        this.seguros2 = this.seguros.filter((seguro: string) => {
          return seguro.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      if (this.seguros2.length == 1) {
        if (confirm("¿" + this.seguros2[0] + "?")) {
          this.setSeguro(this.seguros2[0]);
          document.getElementById("autPor").focus();
        }
      }
    },

    showModalMethod7() {
      this.seguros2 = this.seguros;
      this.showModal7 = !this.showModal7;
      // document.getElementById("oficio").focus();
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    deleteService(it: any) {
      if (confirm("Está Seguro que desea Eliminar Esta Consulta?")) {
        this.lote.consultas.splice(it, 1);
      }
    },

    editService(index: number, consulta: any) {
      this.currentEdition = consulta;
      this.showModalMethod();
    },

    newFormatDate(dateValue: Date) {
      // let out = moment(dateValue).add(0, "h");
      // return moment(out).format("DD/MM/YYYY");
      moment.locale("es");
      return moment(dateValue).calendar();
      // .startOf("hour")
      // .fromNow();
    },

    async addActivity() {
      if (this.currentConsulta !== "") {
        this.lote.consultas.push({
          description: this.currentConsulta,
          date: new Date(),
        });
        this.currentConsulta = "";
      }
      document.getElementById("barcode").focus();
    },

    calcularEdad(date: any) {
      let years = 0;
      let edad = Math.floor(
        moment(new Date()).diff(moment(date, "YYYY-MM-DD"), "years", true)
      );
      if (edad > 120 || edad < 0) {
        years = 0;
      } else {
        years = edad;
      }
      this.lote.edad = years;
    },

    // async filterLotes() {
    //   this.toggleLoading();
    //   try {
    //     const res = await getPeg();
    //     this.lotesPeg = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   try {
    //     const res = await getEmp();
    //     this.lotesEmp = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   try {
    //     const res = await getMez();
    //     this.lotesMez = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   this.toggleLoading();
    // },

    // async loadLigas() {
    //   // this.toggleLoading();
    //   try {
    //     const res = await getLigas();
    //     this.ligas = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   // this.toggleLoading();
    // },

    // async loadVitolas() {
    //   // this.toggleLoading();
    //   try {
    //     const res = await getVitolas();
    //     this.vitolas = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   // this.toggleLoading();
    // },

    async loadLotes() {
      this.toggleLoading();
      try {
        const res = await getLotes(this.criterio);
        this.seguros = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    fixTime() {
      if (this.lote.fechAutor) {
        this.lote.fechAutor = this.formatDateToFix(
          this.lote.fechAutor,
          false
        );
      }
      if (this.lote.ing) {
        this.lote.ing = this.formatDateToFix(this.lote.ing, false);
      }
      if (this.lote.egr) {
        this.lote.egr = this.formatDateToFix(this.lote.egr, false);
      }
      if (this.lote.fechaNacimiento) {
        this.lote.fechaNacimiento = this.formatDateToFix(
          this.lote.fechaNacimiento,
          false
        );
      }
    },

    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        let out = moment(dateValue).add(4, "hours");
        return moment(out).format("yyyy-MM-DD");
      }
    },

    async loadPatient(aflNSS: string) {
      this.toggleSpinner();
      try {
        const { data } = await GetByAflNSS(aflNSS);
        this.lote.cedu = data.cedula;
        this.lote.nombPac = data.nombre;
        this.lote.fechAutor = this.formatDateToFix(data.updatedAt, false);
        this.lote.nombARS = data.seguro;
        this.lote.sex = data.sexo;
        this.lote.telMed = data.telefono;
        this.lote.dx = data.diagnosticoPresuntivo;
        this.lote.fechaNacimiento = this.formatDateToFix(
          data.fechaNacimiento,
          false
        );
        this.lote.edad = data.edad;
        this.lote.dir = data.direccion;
        document.getElementById("aut").focus();
        // this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleSpinner();
    },

    async loadLote(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getLote(id);
        this.lote = data;
        // this.fixTime();
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
    },

    async handleUpdate() {
      this.toggleLoading();
      try {
        if (typeof this.$route.params.id === "string") {
          this.lote.userMod = this.$store.state.user.usuario;
          await updateLote(this.$route.params.id, this.lote);
          // this.addMessage();
          this.$router.push("/lotes");
        }
      } catch (error) {
        //console.error(error);
      }
      this.toggleLoading();
      // this.toggleAlert();
    },

    async handleDelete() {
      if (confirm(this.mensageConfirm)) {
        try {
          if (typeof this.$route.params.id === "string") {
            await deleteLote(this.$route.params.id);
            // this.addMessage();
            this.$router.push("/lotes");
          }
        } catch (error) {
          //console.error(error);
        }
      }
    },

    showDeleteMethod() {
      if (this.$store.state.user.type == "Power User") {
        this.showDelete = true;
      }
    },

    toggleShowDatosPadre() {
      this.showDatosPadre = !this.showDatosPadre;
    },
    toggleShowDatosMadre() {
      this.showDatosMadre = !this.showDatosMadre;
    },
    toggleShowDatosTutor() {
      this.showDatosTutor = !this.showDatosTutor;
    },

    async addMessage() {
      try {
        const res = await createMensaje(this.message);
      } catch (error) {
        // console.error(error);
      }
    },

    isError(message: string) {
      if (message == this.mensageExito) {
        return "success";
      } else {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcLote() {
      (this.lote.retension = this.lote.bruto * 0.1),
        (this.lote.neto = this.lote.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(4, "hours");
      return moment(out).format("MM");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fillFields() {
      // this.lote.instit = "Instituto Avanzado de Artroscopía y Artroplastía";
      this.lote.nombPres = "Dr. Carlos Morales Ángeles";
      this.lote.tipServ = "Cirugía";
      // this.lote.descServ = "Consulta";
      this.lote.tipo = "med";
      this.lote.lote = 0;
      // this.lote.montRec = 500;

      // this.lote.fechaNac = new Date();
      // this.lote.fechaCont = new Date();
    },

    async loadOneLote() {
      try {
        const res = await getOneLote();
        this.oneLote = res.data;
        this.one = this.oneLote[0];
        if (typeof this.one.no === "number") {
          this.nextNo = this.one.no + 1;
        } else {
          this.one.no = 0;
          this.nextNo = this.one.no + 1;
        }
        if (this.nextNo == null) {
          this.nextNo = 0;
        }
        this.lote.no = this.nextNo;
        this.lote.principal = this.nextNo;
        this.lote.principal = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveLotea() {
      await this.loadOneLote();
      try {
        const res = await createLotea(this.lote);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveLote() {
      this.toggleLoading();
      try {
        try {
          const res = await getOneLote();
          this.oneLote = res.data;
          this.one = this.oneLote[0];
          this.nextNo = this.one.no + 1;
          this.lote.no = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.lote.userReg = this.$store.state.user.usuario;
        this.lote.aut = this.lote.aut.toUpperCase().trim();
        const res = await createLote(this.lote).then(
          (res) => {
            this.error = this.respuesta = res.data.title;
            // this.$router.push("/");
            this.res = res;
            this.respuesta = res.data;
            // // this.addMessage();
          },
          (err) => {
            // console.log(err.response);
            this.error = err.response.data.error;
            this.showMessageMethod();
            document.getElementById("btnAcept").focus();
          }
        );
        // this.$router.push("/lotes/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      if (this.error !== this.mensageError) {
        await this.cleanFields();
      }
      await this.fillFields();
      await this.fixTime();
      document.getElementById(this.campoFocus).focus();
      this.toggleAlert();
    },

    async deleteAllLotes() {
      try {
        const res = await eliminateLotes(this.lote);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    cleanFields() {
      this.lote.aflNSS = "";
      this.lote.cedu = "";
      this.lote.nombPac = "";
      this.lote.sex = "";
      this.lote.telMed = "";
      this.lote.fechaNacimiento = "";
      this.lote.edad = "";
      this.lote.dir = "";
      this.lote.aut = "";
      this.lote.dx = "";
      this.lote.nombARS = "";
      this.lote.tipServ = "";
      this.lote.montRec = "";
      this.lote.fechAutor = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    toggleSpinner() {
      this.showSpinner = !this.showSpinner;
    },

    focus() {
      document.getElementById(this.campoFocus).focus();
    },
    focus2() {
      document.getElementById(this.campoFocus2).focus();
    },

    isMed() {
      if (this.lote.tipo == "cent") {
        this.lote.nombPres = "Clínica Cruz Jiminián";
        return false;
      } else {
        return true;
      }
    },
  },
});
