
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";

import { Factura } from "@/interfaces/Factura";
import { create, destroy } from "@/services/facturas/Factura";
import { getone, updatencf } from "@/services/contribuyentes/Contribuyente";

import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "facturas-form",
  components: {
    Navbar,
  },
  data() {
    return {
      contEmis: {} as any,
      contRecep: {} as any,
      reclamos: [] as any[],
      facturas: [] as Factura[],
      subs: [],
      preSubs: [],
      num1: 0,
      num2: 0,
      fechaActual: "",
      search: "",
      seguros2: [],

      currentEdition: {} as any,
      // vitolas: [] as Vitola[],
      // facturas: [] as Factura[],
      // facturasEmp: [] as Factura[],
      // facturasPeg: [] as Factura[],
      // facturasMez: [] as Factura[],
      campoFocus: "fechaEmision",
      mensageError:
        "Ya Existe un Factura Registrado con este Número de Autorización",
      mensageExito: "Factura Registrado Exitosamente",
      mensageConfirm: "¿Está Seguro que desea Eliminar Este Factura?",
      encabezado: "",
      modoForm: "",
      // funciones: [] as Funcion[],
      showDatosPadre: false,
      showDatosMadre: false,
      showDatosTutor: false,
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      showMessage: false,
      loadedAfiliado: {},
      cargando: false,
      showSpinner: false,
      factura: {} as Factura,
      one: {},
      nextNo: Number,
      facturaSelected: [],
      clienteSelected: [],
      descripcionCliente: "",

      showInComentary: "",
      showModal: false,
      showModal7: false,
      showModalAseguradoras: false,
      showDelete: false,
      currentConsulta: "",
      estado: {} as object,
    };
  },

  async mounted() {
    if (this.$route.fullPath == "/facturaas/new") {
      this.modoForm = "add";
      this.encabezado = "Nueva Factura";
    } else {
      this.modoForm = "show";
      this.encabezado = "Detalles de Factura";
    }

    if (this.modoForm == "add") {
      this.fillFields();
      this.fixTime();
    } else {
      this.showDeleteMethod();
      if (typeof this.$route.params.id === "string") {
        await this.loadFactura(this.$route.params.id);
      }
    }
    this.focus();
  },

  methods: {
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },

    async getCont(rncCedula: string, cont: string) {
      this.toggleSpinner();
      try {
        const res = await getone(rncCedula);
        if (cont == "rncEmisor") {
          this.contEmis = res.data;
          this.factura.razSocEmisor = this.contEmis.razonSocial;
        } else {
          this.contRecep = res.data;
          this.factura.razSocRecep = this.contRecep.razonSocial;

          switch (this.contRecep.tipoNCFFact) {
            case "B01":
              this.factura.ncf =
                "B01" + this.formatSecuence(this.contEmis.nextB01);
              this.factura.venceNcf = this.contEmis.venceB01;
              break;
            case "B15":
              this.factura.ncf =
                "B15" + this.formatSecuence(this.contEmis.nextB15);
              break;
          }
        }
      } catch (error) {
        // console.error(error);
      }
      this.toggleSpinner();
    },

    calcISR() {
      this.factura.isr = (this.factura.subTot * 0.1).toFixed(2);
      this.factura.total = (this.factura.subTot - this.factura.isr).toFixed(2);
    },
    showMessageMethod() {
      this.showMessage = !this.showMessage;
    },
    setService() {
      if (this.factura.tipServ == "Consulta") {
        this.factura.descServ = "Consulta";
      } else {
        this.factura.descServ = "";
      }
    },

    showModalAseguradorasMethod() {
      this.seguros2 = this.seguros;
      this.showModalAseguradoras = !this.showModalAseguradoras;
    },

    async redirect(id: string) {
      // alert("Redirecting");
      this.$router.push(`/facturas/${id}`);

      // Remounted
      this.modoForm = "show";
      this.encabezado = "Detalles de Factura";
      this.showDeleteMethod();
      await this.loadFactura(id);
      this.defSubs();

      // this.factura.finVac = new Date();
      // this.factura.fechaNac = new Date();
      // this.factura.fechaCont = new Date();
      this.fixTime();
      this.focus();

      this.showModalMethod7();
    },

    // async buscando() {
    //   this.toggleLoading();
    //   try {
    //     const res = await getFacturas(this.criterio);
    //     this.facturas = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   if (this.factura.barcode !== "Todos") {
    //     this.facturas = this.facturas.filter((factura: Factura) => {
    //       return factura.nombPac
    //         .toLowerCase()
    //         .includes(this.factura.barcode.toLowerCase());
    //     });
    //   }
    //   this.toggleLoading();
    // },

    async changeFocus() {
      document.getElementById("fechAutor").focus();
      await this.buscando();
      this.showModalMethod7();
    },
    calcVenc(date: any) {
      this.factura.mesVenc = moment(date).format("MM");
      this.factura.anoVenc = moment(date).format("YYYY");
    },

    // defSubs() {
    //   this.preSubs = this.almacenes.filter((almacen: Factura) => {
    //     return almacen.desc
    //       .toLowerCase()
    //       .includes(this.factura.almacn.toLowerCase());
    //   });
    //   this.subs = this.preSubs[0].subs;
    // },

    async setSeguro(seguro: string) {
      this.factura.nombARS = seguro;
      this.showModalAseguradorasMethod();
      this.search = "";
      document.getElementById("fechAutor").focus();
      await this.loadReclamosFactura(seguro);
    },

    buscar(term: string) {
      this.seguros2 = this.seguros;
      if (term !== "") {
        this.seguros2 = this.seguros.filter((seguro: string) => {
          return seguro.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      if (this.seguros2.length == 1) {
        if (confirm("¿" + this.seguros2[0] + "?")) {
          this.setSeguro(this.seguros2[0]);
          document.getElementById("autPor").focus();
        }
      }
    },

    showModalMethod7() {
      this.seguros2 = this.seguros;
      this.showModal7 = !this.showModal7;
      // document.getElementById("oficio").focus();
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    deleteService(it: any) {
      if (confirm("Está Seguro que desea Eliminar Esta Consulta?")) {
        this.factura.consultas.splice(it, 1);
      }
    },

    editService(index: number, consulta: any) {
      this.currentEdition = consulta;
      this.showModalMethod();
    },

    newFormatDate(dateValue: Date) {
      // let out = moment(dateValue).add(0, "h");
      // return moment(out).format("DD/MM/YYYY");
      moment.locale("es");
      return moment(dateValue).calendar();
      // .startOf("hour")
      // .fromNow();
    },

    async addActivity() {
      if (this.currentConsulta !== "") {
        this.factura.consultas.push({
          description: this.currentConsulta,
          date: new Date(),
        });
        this.currentConsulta = "";
      }
      document.getElementById("barcode").focus();
    },

    calcularEdad(date: any) {
      let years = 0;
      let edad = Math.floor(
        moment(new Date()).diff(moment(date, "YYYY-MM-DD"), "years", true)
      );
      if (edad > 120 || edad < 0) {
        years = 0;
      } else {
        years = edad;
      }
      this.factura.edad = years;
    },

    // async filterFacturas() {
    //   this.toggleLoading();
    //   try {
    //     const res = await getPeg();
    //     this.facturasPeg = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   try {
    //     const res = await getEmp();
    //     this.facturasEmp = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   try {
    //     const res = await getMez();
    //     this.facturasMez = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }

    //   this.toggleLoading();
    // },

    // async loadLigas() {
    //   // this.toggleLoading();
    //   try {
    //     const res = await getLigas();
    //     this.ligas = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   // this.toggleLoading();
    // },

    // async loadVitolas() {
    //   // this.toggleLoading();
    //   try {
    //     const res = await getVitolas();
    //     this.vitolas = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   // this.toggleLoading();
    // },

    // async loadFacturas() {
    //   this.toggleLoading();
    //   try {
    //     const res = await getFacturas(this.criterio);
    //     this.seguros = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   this.toggleLoading();
    // },

    fixTime() {
      if (this.factura.fechaEmision) {
        this.factura.fechaEmision = this.formatDateToFix(
          this.factura.fechaEmision,
          false
        );
      }
      if (this.factura.venceNcf) {
        this.factura.venceNcf = this.formatDateToFix(
          this.factura.venceNcf,
          false
        );
      }
    },

    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        let out = moment(dateValue).add(0, "hours");
        return moment(out).format("yyyy-MM-DD");
      }
    },

    // async loadReclamosFactura(seguro: string) {
    //   this.toggleLoading();
    //   try {
    //     // const { data } = await getReclamosFactura(seguro);
    //     this.reclamos = data;
    //     // this.fixTime();
    //   } catch (error) {
    //     //console.error(error);
    //   }
    //   this.toggleLoading();
    // },

    // async putReclamosFactura(seguro: string, facturaNo: number) {
    //   this.toggleLoading();
    //   try {
    //     const { data } = await postReclamosFactura(seguro, facturaNo);
    //     this.reclamos = data;
    //     // this.fixTime();
    //   } catch (error) {
    //     //console.error(error);
    //   }
    //   this.toggleLoading();
    // },

    // async loadFactura(id: string) {
    //   this.toggleLoading();
    //   try {
    //     const { data } = await getFactura(id);
    //     this.factura = data;
    //     // this.reclamos0 = await getReclamosFactura2(this.factura.no);
    //     this.reclamos = this.reclamos0.data;
    //     // this.fixTime();
    //   } catch (error) {
    //     //console.error(error);
    //   }
    //   this.toggleLoading();
    // },

    // async handleUpdate() {
    //   this.toggleLoading();
    //   try {
    //     if (typeof this.$route.params.id === "string") {
    //       this.factura.userMod = this.$store.state.user.usuario;
    //       await updateFactura(this.$route.params.id, this.factura);
    //       // this.addMessage();
    //       this.$router.push("/facturas");
    //     }
    //   } catch (error) {
    //     //console.error(error);
    //   }
    //   this.toggleLoading();
    //   // this.toggleAlert();
    // },

    async destroyFactura() {
      if (confirm(this.mensageConfirm)) {
        try {
          if (typeof this.$route.params.id === "string") {
            await destroy(
              this.factura.rncEmisor,
              this.factura.rncRecep + "#" + this.factura.fechaEmision
            );
            // await restartFactura(this.factura.no);
            // this.addMessage();
            this.$router.push("/facturas");
          }
        } catch (error) {
          //console.error(error);
        }
      }
    },

    showDeleteMethod() {
      if (this.$store.state.user.type == "Power User") {
        this.showDelete = true;
      }
    },

    toggleShowDatosPadre() {
      this.showDatosPadre = !this.showDatosPadre;
    },
    toggleShowDatosMadre() {
      this.showDatosMadre = !this.showDatosMadre;
    },
    toggleShowDatosTutor() {
      this.showDatosTutor = !this.showDatosTutor;
    },

    isError(message: string) {
      if (message == this.mensageExito) {
        return "success";
      } else {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcFactura() {
      (this.factura.retension = this.factura.bruto * 0.1),
        (this.factura.neto = this.factura.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "hours");
      return moment(out).format("DD/MM/YYYY");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fillFields() {
      this.factura.fechaEmision = new Date();
      this.factura.rncEmisor = "04900722440";
      this.getCont(this.factura.rncEmisor, "rncEmisor");
    },

    // async loadOneFactura() {
    //   try {
    //     const res = await getOneFactura();
    //     this.oneFactura = res.data;
    //     this.one = this.oneFactura[0];
    //     if (typeof this.one.no === "number") {
    //       this.nextNo = this.one.no + 1;
    //     } else {
    //       this.one.no = 0;
    //       this.nextNo = this.one.no + 1;
    //     }
    //     if (this.nextNo == null) {
    //       this.nextNo = 0;
    //     }
    //     this.factura.no = this.nextNo;
    //     this.factura.principal = this.nextNo;
    //     this.factura.principal = this.nextNo;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    // },

    async createFactura() {
      this.toggleLoading();
      this.factura.rncReceptorFechaEmision =
        this.factura.rncRecep + "#" + this.factura.fechaEmision;
      this.factura.subTot = parseFloat(this.factura.subTot).toFixed(2);
      this.factura.isr = parseFloat(this.factura.isr).toFixed(2);
      this.factura.total = parseFloat(this.factura.total).toFixed(2);
      this.factura.fechaEmision = this.formatDate(this.factura.fechaEmision);
      try {
        const res = await create(this.factura);
        this.$store.state.user.factura = res.data.Item;
        let updateKey;
        let updateValue;
        if (this.contRecep.tipoNCFFact == "B01") {
          updateKey = "nextB01";
          updateValue = this.contEmis.nextB01 + 1;
        } else {
          updateKey = "nextB15";
          updateValue = this.contEmis.nextB15 + 1;
        }

        await updatencf({
          rncCedula: this.factura.rncEmisor,
          updateKey: updateKey,
          updateValue: updateValue,
        });

        this.$router.push(`/facturaas2/print`);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
      this.toggleLoading();
    },

    async saveFactura() {
      this.toggleLoading();
      try {
        // try {
        //   const res = await getOneFactura();
        //   this.oneFactura = res.data;
        //   this.one = this.oneFactura[0];
        //   this.nextNo = this.one.no + 1;
        //   this.factura.no = this.nextNo;
        // } catch (error) {
        //   // // console.error(error);
        // }
        this.factura.userReg = this.$store.state.user.usuario;
        // this.factura.aut = this.factura.aut.toUpperCase().trim();
        this.factura.cantTotal = this.reclamos.cantTotal;
        this.factura.montoTotal = this.reclamos.montoTotal;
        // try {
        //   const { data } = await postReclamosFactura(
        //     this.factura.nombARS,
        //     this.factura.no
        //   );
        //   this.reclamos = data;
        //   // this.fixTime();
        // } catch (error) {
        //   //console.error(error);
        // }
        // const res = await createFactura(this.factura).then(
        //   (res) => {
        //     this.error = this.respuesta = res.data.title;
        //     // this.$router.push("/");
        //     this.res = res;
        //     this.respuesta = res.data;
        //     // // this.addMessage();
        //   },
        //   (err) => {
        //     // console.log(err.response);
        //     this.error = err.response.data.error;
        //     this.showMessageMethod();
        //     document.getElementById("btnAcept").focus();
        //   }
        // );
        // this.$router.push("/facturas/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();
      if (this.error !== this.mensageError) {
        await this.cleanFields();
      }
      await this.fillFields();
      document.getElementById(this.campoFocus).focus();
      this.toggleAlert();
    },

    // async deleteAllFacturas() {
    //   try {
    //     const res = await eliminateFacturas(this.factura);
    //     // // console.log(res);
    //   } catch (error) {
    //     // // console.error(error);
    //   }
    // },

    cleanFields() {
      this.factura.aflNSS = "";
      this.factura.cedu = "";
      this.factura.nombPac = "";
      this.factura.sex = "";
      this.factura.telMed = "";
      this.factura.fechaNacimiento = "";
      this.factura.edad = "";
      this.factura.dir = "";
      this.factura.dx = "";
      this.factura.nombARS = "";
      this.factura.tipServ = "";
      this.factura.montRec = "";
      this.factura.fechAutor = "";
      this.reclamos = [];
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    toggleSpinner() {
      this.showSpinner = !this.showSpinner;
    },

    focus() {
      document.getElementById(this.campoFocus).focus();
    },
    focus2() {
      document.getElementById(this.campoFocus2).focus();
    },

    isMed() {
      if (this.factura.tipo == "cent") {
        this.factura.nombPres = "Clínica Cruz Jiminián";
        return false;
      } else {
        return true;
      }
    },
  },
});
