import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6390917e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "gridlistareportes" }
const _hoisted_2 = { class: "card-header d-flex w-100 justify-content-between" }
const _hoisted_3 = { class: "mb-1 text-white" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = { class: "card-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.reportes, (reporte, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass([$options.toColor(reporte.type), "card text-white bg-primary mb-3"]),
        style: {"max-width":"20rem"}
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h5", _hoisted_3, _toDisplayString(reporte.solicitado), 1),
          _createElementVNode("small", null, [
            _createElementVNode("span", {
              class: _normalizeClass($options.toColor2(reporte.status))
            }, _toDisplayString(reporte.status), 3)
          ])
        ]),
        _createElementVNode("div", {
          style: {"cursor":"pointer"},
          onClick: ($event: any) => (this.$router.push(`/reportes/${reporte._id}`)),
          class: "card-body"
        }, [
          _createElementVNode("h4", _hoisted_5, _toDisplayString(reporte.description), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(reporte.detalles), 1),
          _createElementVNode("small", null, [
            _createElementVNode("span", null, _toDisplayString($options.newFormatDate(reporte.createdAt)), 1)
          ])
        ], 8, _hoisted_4)
      ], 2))
    }), 128))
  ]))
}