
import { defineComponent } from "vue";
import { Expediente } from "@/interfaces/Expediente";
import {
  deleteExpediente,
  getExpediente,
  updateExpediente,
} from "@/services/expedientesclinicos/Expediente";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "expediente-list",
  data() {
    return {
      cargando: false,
      expediente: {} as Expediente,
    };
  },
  methods: {
    toPrint() {
      window.print();
    },
    toggleLoading() {
      this.cargando = !this.cargando;
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("D/MM/yyyy HH:mm");
    },

    formatDate2(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("D/MM/yyyy");
    },

    async loadExpediente(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getExpediente(id);
        this.expediente = data;
      } catch (error) {
        //console.error(error);
      }
      await this.toggleLoading();
      this.toPrint();

      if (this.$store.state.ids.length == 0) {
        this.$router.push(`/expedientes/${this.expediente._id}`);
      } else {
        this.$router.push(`/expedienteslot/new`);
      }

      // this.$router.push(`/expedientes/${this.expediente._id}`);
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateExpediente(this.$route.params.id, this.expediente);
          this.$router.push("/expedientes");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteExpediente(this.$route.params.id);
          this.$router.push("/expedientes");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadExpediente(this.$route.params.id);
    }
  },

  // beforeUnmount() {
  //   this.toPrint();
  // }
});
